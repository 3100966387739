import React, { useState } from 'react'
import Menu from '../../components/navBar/Menu'
import Header from '../../components/navBar/Header'
import { useToasts } from 'react-toast-notifications'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
const NewChantier = () => {
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    infoUser?.user?.role?.roleType != 'admin'
  ) {
    return <PageNotFound />
  }
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [nom, setNom] = useState('')
  const [addresse, SetAddresse] = useState('')
  const [photo, SetPhoto] = useState()
  const [client, SetClient] = useState('')
  const [logo, SetLogo] = useState()
  const saveChantier = () => {
    const formData = new FormData()
    formData.append('photo', photo)
    formData.append('logoClient', logo)
    formData.append('titre', nom)
    formData.append('adresse', addresse)
    formData.append('client',client)
    if (nom && addresse && client) {
      addToast(
        'Enregistrement en cours...',
        {
          appearance: 'success',
          autoDismiss: true,
        }
      )
      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/chantiers`,formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${infoUser.token}`,
          },
        })
        .then((res) => {
          console.log(res);
          addToast('Succès', {
            appearance: 'success',
            autoDismiss: true,
          })
          //navigate('/accueil')
        })
        .catch((err) => {
          console.log(err);
          addToast("Une erreur s'est produit", {
            appearance: 'error',
            autoDismiss: true,
          })
        })
    } else {
      addToast(
        'Les champs (nom du chantier, adresse du chantier, nom du client) sont requis.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={false} />
        <Header bool={false} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="page-title">Création d'un nouveau chantier</h4>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              {/* debut wizard */}
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">
                      <h6 class="card-title">
                        {' '}
                        informations nécessaires pour créer un nouveau chantier{' '}
                      </h6>
                    </div>
                    <div class="card-body">
                      <nav>
                        <div class="nav nav-tabs" id="nav-tab">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#step1"
                            id="setp1-tab"
                          >
                            Chantier
                          </a>
                          <a
                            className="nav-link "
                            data-bs-toggle="tab"
                            href="#step2"
                            id="setp2-tab"
                          >
                            Client
                          </a>
                          <a
                            className="nav-link "
                            data-bs-toggle="tab"
                            href="#step3"
                            id="setp3-tab"
                          >
                            Validation
                          </a>
                          {/* {entreprise?.map((e, i) => {
                              return (
                                <>
                                  <a
                                    class={`${
                                      i == 0 ? 'nav-link active' : 'nav-link'
                                    }`}
                                    id={`step22-tab${e._id}`}
                                    data-bs-toggle="tab"
                                    href={`#step22${e._id}`}
                                  >
                                    {e.titre}
                                  </a>
                                </>
                              )
                            })} */}
                        </div>
                      </nav>
                      <form
                        onSubmit={(ev) => {
                          ev.preventDefault()
                          saveChantier()
                        }}
                      >
                        <div class="tab-content" id="nav-tabContent">
                          <div className="tab-pane active" id="step1">
                            <div className="container-fluid">
                              <div class="mb-3 mt-3">
                                <label for="email" class="form-label">
                                  Nom du chantier:
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  onChange={(ev) => setNom(ev.target.value)}
                                />
                              </div>
                              <div class="mb-3 mt-3">
                                <label for="email" class="form-label">
                                  Adresse du chantier:
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  onChange={(ev) =>
                                    SetAddresse(ev.target.value)
                                  }
                                />
                              </div>
                              <div class="mb-3 mt-3">
                                <label for="email" class="form-label">
                                  Photo du chantier:
                                </label>
                                <input
                                  type="file"
                                  class="form-control"
                                  accept='image/*'
                                  onChange={(ev) =>
                                    SetPhoto(ev.target.files[0])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane " id="step2">
                            <div className="container-fluid">
                              <div class="mb-3 mt-3">
                                <label for="email" class="form-label">
                                  Nom du client:
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  onChange={(ev) => SetClient(ev.target.value)}
                                />
                              </div>
                              <div class="mb-3 mt-3">
                                <label for="email" class="form-label">
                                  logo:
                                </label>
                                <input
                                  type="file"
                                  class="form-control"
                                  accept='image/*'
                                  onChange={(ev) => SetLogo(ev.target.files[0])}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane " id="step3">
                            <div className="container-fluid p-3">
                              <p>
                                Les informations seront enregistrées pour la
                                création d'un nouveau chantier. Veuillez noter
                                que seul l'administrateur peut modifier ces
                                données ultérieurement.{' '}
                              </p>{' '}
                              <br />
                              <button type="submit" className="btn btn-success">
                                Valider
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewChantier
