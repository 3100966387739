import React, { useState, useEffect } from 'react'
import Menu from '../../components/navBar/Menu'
import Header from '../../components/navBar/Header'
import PageNotFound from '../../components/404/PageNotFound'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../../helpers/user/user'
import { ReactTabulator } from 'react-tabulator'
import AddUser from './component/AddUser'
import { BeatLoader } from 'react-spinners'
import { getAllRole } from '../../helpers/suivi/suivi'
import { useToasts } from 'react-toast-notifications'
import { getChantier } from '../../helpers/acceuil/acceuil'
import axios from 'axios'
import { searchOptimizedData } from '../../helpers/recherche/recheche'
const GestionUser = () => {
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const [wait, setWait] = useState(false)
  const [bool, setBool] = useState()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [listUser, setListUser] = useState([])
  const infoChantiers = localStorage.getItem('infoChantiers')
  const [dataAcces, setDataAcces] = useState([])
  const [role, setRole] = useState([])
  const infoChantier = JSON.parse(infoChantiers)
  const [allChantier, setAllChantier] = useState([])
  const [dataModalAcces, setDataModalAcces] = useState({})
  const [maj, setMaj] = useState()
  if (
    !localStorage.getItem('isLogin') ||
    infoUser?.user?.role?.roleType != 'admin'
  ) {
    return <PageNotFound />
  }
  const checkboxAcces = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      data.isIntervenant = !cell.getValue()
      cell.getRow().update(data)
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch mt-2'
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    return view
  }

  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      setWait(true)
      axios
        .patch(
          `${process.env.REACT_APP_URL}/api/v1/users/${data._id}`,
          { envoyerEmailLp: e.target.checked },
          {
            headers: {
              Authorization: `Bearer ${infoUser.token}`,
            },
          }
        )
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          addToast("Une erreur s'est produit.", {
            appearance: 'error',
            autoDismiss: true,
          })
          setBool(!bool)
        })
        .finally((res) => setWait(false))
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }
  const checkbox1 = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      setWait(true)
      axios
        .patch(
          `${process.env.REACT_APP_URL}/api/v1/users/${data._id}`,
          { envoyerEmailRappelEtRelance: e.target.checked },
          {
            headers: {
              Authorization: `Bearer ${infoUser.token}`,
            },
          }
        )
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          addToast("Une erreur s'est produit.", {
            appearance: 'error',
            autoDismiss: true,
          })
          setBool(!bool)
        })
        .finally((res) => setWait(false))
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }
  const checkbox2 = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      setWait(true)
      axios
        .patch(
          `${process.env.REACT_APP_URL}/api/v1/users/${data._id}`,
          { envoyerEmailRapport: e.target.checked },
          {
            headers: {
              Authorization: `Bearer ${infoUser.token}`,
            },
          }
        )
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          addToast("Une erreur s'est produit.", {
            appearance: 'error',
            autoDismiss: true,
          })
          setBool(!bool)
        })
        .finally((res) => setWait(false))
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = cell.getValue()
    input.disabled = true
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }
  const selectRole = (cell) => {
    const data = cell.getData()
    const ul = document.createElement('ul')
    ul.className = 'dropdown-menu'
    role.map((el) => {
      const option = document.createElement('li')
      option.className = 'dropdown-item'
      option.innerText = `${el.roleName}`
      option.addEventListener('click', function (e) {
        e.preventDefault()
        data.roles = el._id ? el._id : 'Non défini'
        data.roleName = el.roleName ? el.roleName : 'Non défini'
        cell.getRow().update(data)
      })
      ul.appendChild(option)
    })
    const btn = document.createElement('button')
    btn.className = 'btn'
    btn.type = 'button'
    btn.addEventListener('click', function (e) {
      e.preventDefault()
    })
    btn.setAttribute('data-bs-toggle', 'dropdown')
    const span = document.createElement('span')
    const icon = document.createElement('i')
    icon.className = 'ti ti-selector'
    span.innerText = data.roleName
    btn.appendChild(span)
    btn.appendChild(icon)
    const container = document.createElement('div')
    container.className = 'dropdown dropend'
    container.style = 'position:absolute;z-index:999'
    container.appendChild(btn)
    container.appendChild(ul)
    return container
  }

  const modalAcces = (dellFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalAcces')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setMaj(cell)
      setDataModalAcces(cell.getData())
      const data = cell.getData()
      let listAcces = allChantier.map((el) => {
        return {
          chantierId: el._id,
          roles: role[0]._id,
          isIntervenant: false,
          titre: el.titre,
          roleName: role[0].roleName,
        }
      })
      let accesExisted = data.acces.map((el) => {
        return {
          chantierId: el.chantierId?._id ? el.chantierId._id : 'Non défini',
          roles: el.roles?._id ? el.roles._id : 'Non défini',
          isIntervenant: true,
          titre: el.chantierId?.titre ? el.chantierId.titre : 'Non défini',
          roleName: el.roles?.roleName ? el.roles.roleName : 'Non défini',
        }
      })
      let mergedAcces = {}
      ;[...listAcces, ...accesExisted].forEach((el) => {
        if (!mergedAcces[el.chantierId] || el.isIntervenant) {
          mergedAcces[el.chantierId] = el
        }
      })
      let finalAcces = Object.values(mergedAcces)
      setDataAcces(finalAcces)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }

  const colonneAcces = [
    {
      title: 'Chantier',
      field: 'titre',
      formatter: simpleInput,
    },
    {
      title: 'Intervenant',
      field: 'isIntervenant',
      formatter: checkboxAcces,
    },
    {
      title: 'role',
      field: 'roles',
      formatter: selectRole,
    },
  ]

  const colonneUser = [
    { title: 'id', width: 100, field: '_id', formatter: simpleInput },
    { title: 'Nom', field: 'name', editor: 'input', formatter: simpleInput },
    {
      title: 'Prenom',
      field: 'lastname',
      editor: 'input',
      formatter: simpleInput,
    },
    { title: 'Email', field: 'email', editor: 'input', formatter: simpleInput },
    {
      title: 'Telephone',
      field: 'telephone',
      editor: 'input',
      formatter: simpleInput,
    },
    /* {
      title: 'DUREE',
      field: 'duration',
    }, */
    {
      title: 'Accès par chantier',
      field: '',
      formatter: modalAcces(),
    },
    { title: 'Mail LTP', field: 'envoyerEmailLp', formatter: checkbox },
    {
      title: 'Mail Rappel',
      field: 'envoyerEmailRappelEtRelance',
      formatter: checkbox1,
    },
    {
      title: 'Mail Rapport',
      field: 'envoyerEmailRapport',
      formatter: checkbox2,
    },
    /*  {
      title:'Privilège',
      field:'role.roleType',
    } */
  ]

  useEffect(() => {
    setWait(true)
    getChantier(infoUser.token)
      .then((res) => {
        if (res.data.chantiers) {
          setAllChantier(res.data.chantiers)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    getAllRole(infoUser.token).then((res) => {
      if (res.data) {
        setRole(res.data)
      }
    })
    getUser(infoUser.token)
      .then((res) => {
        console.log(res.data)
        setListUser(res.data)
      })
      .catch((err) => console.log(err))
      .finally((el) => setWait(false))
  }, [bool])
  const userEdited = (cell) => {
    const data = cell.getData()
    setWait(true)
    axios
      .patch(
        `${process.env.REACT_APP_URL}/api/v1/users/${data._id}`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
        setBool(!bool)
      })
      .finally((res) => setWait(false))
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={false} />
        <Header bool={false} />
      </div>
      <div class="page-wrapper">
        <div class="page-content-tab">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <h4 class="page-title">Gestion utilisateurs</h4>
                </div>
              </div>
            </div>
            {/*  <div class="row">
              <div class="col-lg-12">
                <div class="d-flex justify-content-between mb-3">
                  <div>
                    <h6>Liste des utilisateurs</h6>
                  </div>
                  <div class="align-self-center">
                    {(infoUser?.user?.role?.roleType == 'admin' ||
                      infoUser?.user?.role?.roleType == 'opc') && (
                      <>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal">
                          <i
                            data-feather="download"
                            class="align-self-center icon-xs me-2"></i>
                          + Ajouter
                        </button>
                        <AddUser
                          bool={bool}
                          setBool={setBool}
                          roles={role}
                          allChantier={allChantier}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
 */}
            <div class="row" style={{ backgroundColor: 'white',borderRadius:'12px' }}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '30px 20px',
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '20px',
                      fontWeight: '600',
                    }}>
                    Listes des utilisateurs
                  </span>
                  <span
                    style={{
                      backgroundColor: '#E0E7FF',
                      borderRadius: '24px',
                      padding: '6px 12px',
                      color: '#64748B',
                      fontSize: '14px',
                      fontWeight: '600',
                      marginLeft: '8px',
                      maxHeight: '32px',
                      width: 'auto',
                    }}>
                    {listUser.length > 0 ? listUser.length : '0'} Utilisateurs
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      padding: '8px 12px 8px 12px',
                      backgroundColor: 'rgba(226, 232, 240, 0.6)',
                      borderRadius: '8px',
                      height: '40px',
                    }}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#818CF8"
                        style={{ width: '18px', height: '18px' }}>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </span>
                    <input
                      type="text"
                      placeholder="Recherche"
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                      }}
                      onChange={(ev) => {
                        ev.preventDefault()
                        ev.stopPropagation()
                        const newData = searchOptimizedData(
                          ev.target.value,
                          listUser
                        )
                        setListUser(newData)
                      }}
                    />
                  </span>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#myModal"
                    style={{marginLeft:'10px'}}
                    >
                    <i
                      data-feather="download"
                      class="align-self-center icon-xs me-2"></i>
                    + Ajouter
                  </button>
                  <AddUser
                    bool={bool}
                    setBool={setBool}
                    roles={role}
                    allChantier={allChantier}
                  />
                  {/* <button
                    className="btn"
                    style={{
                      backgroundColor: '#4F46E5',
                      color: '#F8FAFC',
                      marginLeft: '24px',
                    }}
                    onClick={(e) => addPlanning(e)}>
                    + Nouvelle tâche
                  </button> */}
                </div>
              </div>
              <div class="col-lg-12">
                <ReactTabulator
                  data={listUser ? listUser : []}
                  columns={colonneUser}
                  layout={'fitColumns'}
                  events={{ cellEdited: userEdited }}
                  options={{
                    locale: true,
                    pagination: 'local',
                    paginationSize: 10,
                    langs: {
                      fr: {
                        pagination: {
                          first: 'Premier',
                          first_title: 'Première page',
                          last: 'Dernier',
                          last_title: 'Dernière page',
                          prev: 'Précédent',
                          prev_title: 'Page précédente',
                          next: 'Suivant',
                          next_title: 'Page suivante',
                          page_size: 'Taille de page',
                          page_title: 'Afficher la page',
                          all: 'Tous',
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Acces */}
      <div class="modal fade" id="myModalAcces">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Autorisation et role pur chaque chantier
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                axios
                  .patch(
                    `${process.env.REACT_APP_URL}/api/v1/users/${dataModalAcces._id}`,
                    {
                      ...dataModalAcces,
                      acces: dataAcces.filter((el) => el.isIntervenant == true),
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${infoUser.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res)
                    setBool(!bool)
                  })
                  .catch((err) => {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                    setBool(!bool)
                  })
                  .finally((res) => {
                    setDataAcces([])
                    setWait(false)
                  })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3 cibleTabulatorCrIntemperie">
                  <label for="comment">Liste chantier:</label>
                  <ReactTabulator
                    data={dataAcces ? dataAcces : []}
                    columns={colonneAcces}
                    layout={'fitColumns'}
                    options={{
                      locale: true,
                      pagination: 'local',
                      paginationSize: 5,
                      langs: {
                        fr: {
                          pagination: {
                            first: 'Premier',
                            first_title: 'Première page',
                            last: 'Dernier',
                            last_title: 'Dernière page',
                            prev: 'Précédent',
                            prev_title: 'Page précédente',
                            next: 'Suivant',
                            next_title: 'Page suivante',
                            page_size: 'Taille de page',
                            page_title: 'Afficher la page',
                            all: 'Tous',
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {wait && (
        <>
          <div
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
      )}
    </>
  )
}

export default GestionUser
