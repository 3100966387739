import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'
import { ReactTabulator } from 'react-tabulator'
import './tabulator.scss'
const AddUser = ({ bool, setBool, roles, allChantier }) => {
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const { addToast } = useToasts()
  const [nom, setNom] = useState('')
  const [prenom, setPrenom] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [dataAcces, setDataAcces] = useState([])
  useEffect(() => {
    if (allChantier.length > 0) {
      setDataAcces(
        allChantier.map((el) => {
          return {
            chantier: el._id,
            titre: el.titre,
            isIntervenant: false,
            roles: roles[0]._id,
            roleName:roles[0].roleName
          }
        })
      )
    }
  }, [allChantier])
  const saveUser = (access) => {
    if (nom) {
      const formaData = new FormData()
      formaData.append('name', nom)
      formaData.append('lastname', prenom)
      formaData.append('email', email)
      formaData.append('telephone', tel)
      formaData.append('password', 'Kolos2023@')
      formaData.append('passwordConfirm', 'Kolos2023@')
      formaData.append('acces',JSON.stringify(access))
      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/users`, formaData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${infoUser.token}`,
          },
        })
        .then((res) => {
          console.log(res)
          addToast("L'utilisateur a été enregistré avec succès .", {
            appearance: 'success',
            autoDismiss: true,
          })
          setBool(!bool)
        })
        .catch((err) => {
          console.log(err)
          addToast("Une erreur s'est produit.", {
            appearance: 'error',
            autoDismiss: true,
          })
        })
    } else {
      addToast("Une erreur s'est produit.", {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = cell.getValue()
    input.disabled = true
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }
  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      data.isIntervenant = !cell.getValue()
      cell.getRow().update(data)
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch mt-2'
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    return view
  }
  const selectRole = (cell) => {
    const data = cell.getData()
    const ul = document.createElement('ul')
    ul.className = 'dropdown-menu'
    roles.map((el) => {
      const option = document.createElement('li')
      option.className = 'dropdown-item'
      option.innerText = `${el.roleName}`
      option.addEventListener('click', function (e) {
        e.preventDefault()
        data.roles = el._id
        data.roleName = el.roleName
        cell.getRow().update(data)
      })
      ul.appendChild(option)
    })
    const btn = document.createElement('button')
    btn.className = 'btn'
    btn.type = 'button'
    btn.addEventListener('click', function (e) {
      e.preventDefault()
    })
    btn.setAttribute('data-bs-toggle', 'dropdown')
    const span = document.createElement('span')
    const icon = document.createElement('i')
    icon.className = 'ti ti-selector'
    span.innerText = data.roleName
    btn.appendChild(span)
    btn.appendChild(icon)
    const container = document.createElement('div')
    container.className = 'dropdown dropend'
    container.style = 'position:absolute;z-index:999'
    container.appendChild(btn)
    container.appendChild(ul)
    return container
  }
  const colonneAcces = [
    {
      title: 'Chantier',
      field: 'titre',
      formatter:simpleInput
    },
    {
      title: 'Intervenant',
      field: 'isIntervenant',
      formatter: checkbox,
    },
    {
      title: 'role',
      field: 'roles',
      formatter:selectRole
    },
  ]
  return (
    <div class="modal" id="myModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Nouvel utilisateur</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"></button>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              e.target.reset()
              const access = []
              dataAcces.map((el)=>{
                if(el.isIntervenant){
                  access.push({
                    chantierId:el.chantier,
                    roles:el.roles,
                  })
                }
              })
              saveUser(access)
            }}>
            <div class="modal-body">
              <div class="form-floating mb-3 mt-3">
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => {
                    setNom(e.target.value)
                  }}
                />
                <label>Nom</label>
              </div>{' '}
              <div class="form-floating mb-3 mt-3">
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => {
                    setPrenom(e.target.value)
                  }}
                />
                <label>Prénom</label>
              </div>{' '}
              <div class="form-floating mb-3 mt-3">
                <input
                  type="email"
                  class="form-control"
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
                <label>Email</label>
              </div>{' '}
              <div class="form-floating mb-3 mt-3">
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => {
                    setTel(e.target.value)
                  }}
                />
                <label>Téléphone</label>
              </div>{' '}
              <div class=" mb-3 mt-3 cibleTabulatorCrIntemperie">
                <label htmlFor="">-Autorisation et role de l'utilisateur pour chaque chantier</label>
                <ReactTabulator
                  data={dataAcces ? dataAcces : []}
                  columns={colonneAcces}
                  layout={'fitColumns'}
                  //events={{cellEdited:userEdited}}
                  options={{
                    locale: true,
                    pagination: 'local',
                    paginationSize: 5,
                    langs: {
                      fr: {
                        pagination: {
                          first: 'Premier',
                          first_title: 'Première page',
                          last: 'Dernier',
                          last_title: 'Dernière page',
                          prev: 'Précédent',
                          prev_title: 'Page précédente',
                          next: 'Suivant',
                          next_title: 'Page suivante',
                          page_size: 'Taille de page',
                          page_title: 'Afficher la page',
                          all: 'Tous',
                        },
                      },
                    },
                  }}
                />
              </div>{' '}
            </div>

            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-success"
                data-bs-dismiss="modal">
                Enregistrer
              </button>
              <button
                type="button"
                class="btn btn-dark"
                data-bs-dismiss="modal">
                Fermer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddUser
