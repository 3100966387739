import axios from "axios";

export const addRetard = async (data,planning,lot,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.post(`${process.env.REACT_APP_URL}/api/v1/retards`,{retard:{tache:data},planning:planning,lot:lot},config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e.response
        })
        return result
    }catch(err){
        return {message:"erreur"}
    }
}
export const getRetard = async (planning,page,lots,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    const lot = `lotResponsable=${lots}`
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/retards?planning=${planning}&page=${page}&limit=10&${lots=="false" ? "" : lot }`,config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e.response
        })
        return result
    }catch(err){
        return {message:"erreur"}
    }
}

export const updateRetard = async (data,id,lot,planning,dure,idTache,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/retards/${id}`,{responsableEngage:data,lotResponsable:lot,planning:planning,dure_retard:dure,tacheId:idTache},config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e.response
        })
        return result
    }catch(err){
        return {message:"erreur"}
    }
}
export const updateResponsable = async (responsableEngage,responsable,observation,id,idTache,dure,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/retards/${id}`,{responsableEngage:responsableEngage,lotResponsable:responsable,dure_retard:dure,observation:observation,tacheId:idTache},config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e.response
        })
        return result
    }catch(err){
        return {message:"erreur"}
    }
}

export const getRecap = async (id,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/mails?taches=${id}`,config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e.response
        })
        return result
    }catch(err){
        return {message:"erreur"}
    }
}
