import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { addLot } from '../../../../helpers/suivi/suivi'
const NewLot = ({ allEntreprise,bool,setBool }) => {
  const { addToast } = useToasts()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)

  const [nom, setNom] = useState('Non défini')
  const [titre, setTitre] = useState('Non défini')
  const [description, setDescription] = useState()
  const [couleur, setCouleur] = useState('#CCCCCC')
  const [entreprise, setEntreprise] = useState()
  const [logo,setLogo] = useState()
  const saveLot = () => {
    addToast('Enregistrement en cours...', {
      appearance: 'info',
      autoDismiss: true,
    })
    const formData = new FormData()
    formData.append('titre',nom)
    formData.append('sousTitre',titre)
    formData.append('description',description)
    if(entreprise)formData.append('entreprise',entreprise)
    formData.append('planning',infoChantier.planning)
    formData.append('photoLot',logo)
    addLot(formData,infoUser.token).then((res)=>{
      console.log(res);
     if(res.name == "AxiosError"){
      addToast("Une erreur s'est produit.", {
        appearance: 'error',
        autoDismiss: true,
      })
     }else{
      addToast('succès', {
        appearance: 'success',
        autoDismiss: true,
      })
      setBool(!bool)
     }
    }).catch((err)=>{
      console.log(err);
      addToast("Une erreur s'est produit", {
        appearance: 'error',
        autoDismiss: true,
      })
    })
  }
  return (
    <>
      <div class="modal" id="myModal">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Création nouveau lot</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(ev) => {
                ev.preventDefault()
                ev.target.reset()
                saveLot()
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label class="form-label">Nom du lot:</label>
                  <input type="text" class="form-control" onChange={(champ)=>{
                    setNom(champ.target.value)
                  }} required />
                </div>
                <div class="mb-3 mt-3">
                  <label class="form-label">Titre du lot:</label>
                  <input type="text" class="form-control" onChange={(champ)=>{
                    setTitre(champ.target.value)
                  }} />
                </div>
                <div class="mb-3 mt-3">
                  <label class="form-label">Description du lot:</label>
                  <input type="text" class="form-control" onChange={(champ)=>{
                    setDescription(champ.target.value)
                  }} />
                </div>
                <div class="mb-3 mt-3">
                  <label class="form-label">Entreprise:</label>
                  <select class="form-select" onChange={(champ)=>{
                    setEntreprise(champ.target.value)
                  }}>
                    <option value="" selected>Sélectionner une entreprise</option>
                    {allEntreprise &&
                      allEntreprise.map((el) => {
                        return (
                          <>
                            <option value={el._id}>
                              {el.nom ? el.nom : 'Non défini'}
                            </option>
                          </>
                        )
                      })}
                  </select>
                </div>
                <div class="mb-3 mt-3">
                  <label class="form-label">Logo:</label>
                  <input
                    type="file"
                    accept='image/*'
                    class="form-control form-control-color"
                    onChange={(champ)=>{
                      setLogo(champ.target.files[0])
                    }}
                  />
                </div>
                {/* <div class="mb-3 mt-3">
                  <label class="form-label">Couleur:</label>
                  <input
                    type="color"
                    class="form-control form-control-color"
                    defaultValue="#CCCCCC"
                    onChange={(champ)=>{
                      setCouleur(champ.target.value)
                    }}
                  />
                </div> */}
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewLot
