import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import { updateFavori } from '../../../helpers/planning/favori/favori'
import BeatLoader from 'react-spinners/BeatLoader'
import { getFavori } from '../../../helpers/planning/favori/favori'
import Modal from './Favori/Modal'
import PageNotFound from '../../../components/404/PageNotFound'
import { useSelector } from 'react-redux'
import { getAllData } from '../../../helpers/suivi/suivi'
//Liste des taches noté favori
const Favori = () => {
  const navigate = useNavigate()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
    infoUser?.user?.role?.roleType != 'opc')
  ) {
    return <PageNotFound />
  }
  const bools = useSelector((state) => state.bool.bool)
  const [page, setPage] = useState(1)
  const [nbPage, setNbPage] = useState(0)
  const [bool, setBool] = useState(false)
  const [allTask, setAllTask] = useState([])
  const [allLot, setAllLot] = useState([])
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)

  const [wait, setWait] = useState(false)
  useEffect(() => {
    setWait(true)
    getAllData(`planning=${infoChantier.planning}`, 'lots',infoUser.token).then((e) => {
      console.log("lot");
      setAllLot(e.data)
      setWait(false)
    })
    getFavori(infoChantier.planning, page,infoUser.token).then((el) => {
      console.log(el.data)
      setNbPage((el.length / 10).toFixed(0))
      setAllTask(el.data)
    })
  }, [bool, page, bools])

  //formatter de date en fr
  const dateFr = (value) => {
    const dt = value.split('T')[0]
    const dt1 = dt.split('-')
    const result = `${dt1[2]}/${dt1[1]}/${dt1[0]}`
    return result
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid" style={{ paddingTop: '0.5vw' }}>
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <h4 class="page-title">Planification</h4>
                </div>
              </div>
            </div>
            <ul class="nav nav-pills">
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Dashboard')}
              >
                <a class="nav-link" href="#">
                  Tableau de bord
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_GestionTache')}
              >
                <a class="nav-link" href="#">
                  Gestion des tâches
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Pointage')}
              >
                <a class="nav-link" href="#">
                  Pointage
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Favori')}
              >
                <a class="nav-link active" href="#">
                  Pour mémoire
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Retard')}
              >
                <a class="nav-link" href="#">
                  Retards
                </a>
              </li>
            </ul>

            {/* debut tache favori  */}
            <div className="container-fluid mt-3 bg-light">
              {/* <ReactTabulator
                data={data}
                columns={colonneFavori}
                height={"310px"}
                layout={"fitColumns"}
                events={{ cellEdited: edited }}
              /> */}
              <div className="table-responsive">
                <table
                  class="table table-striped"
                  style={{ marginTop: '1vw', height: '600px' }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th>P.M.</th>
                      <th>Tâche</th>
                      <th>Date début</th>
                      <th>Date fin</th>
                      <th>Avancement</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wait && (
                      <>
                        <div
                          style={{
                            position: 'absolute',
                            top: '40%',
                            left: '45%',
                          }}
                        >
                          <BeatLoader
                            color="yellowgreen"
                            loading={true}
                            size={25}
                          />
                        </div>
                      </>
                    )}
                    {allTask &&
                      allTask.map((e) => {
                        if (e.isCritical) {
                          return (
                            <>
                              <tr>
                                <td>
                                  <div class="form-check form-switch form-switch-success">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      checked
                                      onChange={(el) => {
                                        console.log(el.target.checked)
                                        console.log(e._id)
                                        updateFavori(
                                          el.target.checked,
                                          e._id,
                                          infoUser.token
                                        ).then((e) => {
                                          console.log(e)
                                          setBool(!bool)
                                        })
                                      }}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="customSwitchSuccess"
                                    ></label>
                                  </div>
                                </td>
                                <td>{e.text ? e.text : <>...</>}</td>
                                <td>
                                  {e.start_date ? (
                                    dateFr(e.start_date)
                                  ) : (
                                    <>...</>
                                  )}
                                </td>
                                <td>
                                  {e.end_date ? dateFr(e.end_date) : <>...</>}
                                </td>
                                <td>
                                  <div class="progress">
                                    <div
                                      class="progress-bar"
                                      role="progressbar"
                                      style={{ width: `${e.progress * 100}%` }}
                                      aria-valuenow="10"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {' '}
                                      {`${(e.progress * 100).toFixed(2)}%`}{' '}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <i
                                    class="ti ti-circle-plus"
                                    style={{ fontSize: '30px' }}
                                    data-bs-toggle="modal"
                                    data-bs-target={`#favori${e._id}`}
                                  ></i>
                                </td>
                              </tr>
                              <Modal
                                id={`favori${e._id}`}
                                data={e}
                                lot={allLot}
                                curentLot={e.lot}
                              />
                            </>
                          )
                        }
                      })}
                  </tbody>
                </table>
                <div className="container-fluid">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end align-items-center">
                      <span style={{ marginRight: '5px' }}>
                        il y a {nbPage} page(s)
                      </span>

                      {page > 1 ? (
                        <>
                          <li
                            class={
                              page == 1 ? 'page-item disabled' : 'page-item'
                            }
                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                            onClick={(e) => {
                              if (page > 1) {
                                setPage(page - 1)
                              }
                            }}
                          >
                            <span class="page-link">Précédent</span>
                          </li>
                          <li className="page-link">
                            <a
                              href="#"
                              className="page-ink"
                              onClick={(e) => {
                                setPage(1)
                              }}
                            >
                              1
                            </a>
                          </li>
                        </>
                      ) : (
                        ''
                      )}
                      <li class="page-item active">
                        <a class="page-link" href="#">
                          {page}
                        </a>
                      </li>

                      {page < nbPage ? (
                        <>
                          <li className="page-link">
                            <a
                              href="#"
                              className="page-ink"
                              onClick={(e) => {
                                setPage(nbPage)
                              }}
                            >
                              {nbPage}
                            </a>
                          </li>
                          <li
                            class="page-item"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              if (page < nbPage) {
                                setPage(page + 1)
                              }
                            }}
                          >
                            <span class="page-link">Suivant</span>
                          </li>
                        </>
                      ) : (
                        ''
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            {/* fin tache favori */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Favori
