import React, { useState, useEffect } from 'react'
import Menu from '../../components/navBar/Menu'
import Header from '../../components/navBar/Header'
import PageNotFound from '../../components/404/PageNotFound'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../../helpers/user/user'
import { getAllEntreprise } from '../../helpers/suivi/suivi'
import { ReactTabulator } from 'react-tabulator'
import { BeatLoader } from 'react-spinners'
import AddEnt from './component/AddEnt'
import { updateEnt } from '../../helpers/suivi/suivi'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'
import { searchOptimizedData } from '../../helpers/recherche/recheche'
const GestionEntreprise = () => {
  const navigate = useNavigate()
  const [wait, setWait] = useState(false)
  const [bool, setBool] = useState()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [listEnt, setListEnt] = useState([])
  const [listUser, setListUser] = useState([])
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  if (
    !localStorage.getItem('isLogin') ||
    infoUser?.user?.role?.roleType != 'admin'
  ) {
    return <PageNotFound />
  }
  const inputEnt = (cell) => {
    const data = cell.getData()
    const view = document.createElement('select')
    view.className = 'form-select'

    listUser &&
      listUser.map((user) => {
        const option1 = document.createElement('option')
        option1.value = user._id
        option1.innerText = `${user.name} (${user.email})`
        if (user._id == cell.getData().responsable?._id) {
          option1.selected = true
        }
        view.appendChild(option1)
      })

    view.addEventListener('change', function (e) {
      updateEnt(data._id, { responsable: e.target.value }, infoUser.token)
        .then((res) => {
          console.log(res)
          setBool(!bool)
        })
        .catch((err) => {
          console.log(err)
        })
    })
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = cell.getValue()
    input.disabled = cell.getField() == 'nom' ? false : true
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }

  const colonneEnt = [
    { title: 'id', width: 100, field: '_id', formatter: simpleInput },
    { title: 'Nom', field: 'nom', formatter: simpleInput },
    // { title: 'SIRET', field: 'siret', editor: 'input',default:"Non défini" },
    { title: 'Responsable', field: '', formatter: inputEnt },
    { title: 'Email', field: 'responsable.email', formatter: simpleInput },
    {
      title: 'Telephone',
      field: 'responsable.telephone',
      formatter: simpleInput,
    },
    /* {
      title: 'DUREE',
      field: 'duration',
    }, */
  ]
  const entEdited = (cell) => {
    const data = cell.getData()
    setWait(true) 
    updateEnt(
      data._id,
      { ...data, responsable: data.responsable?._id },
      infoUser.token
    )
      .then((res) => {
        console.log(res)
        setWait(false)
      })
      .catch((err) => {
        console.log(err)
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
        setWait(false)
      })
  }
  useEffect(() => {
    setWait(true)
    getAllEntreprise(infoUser.token)
      .then((res) => {
        setListEnt(res.data)
      })
      .catch((err) => console.log(err))
      .finally((res) => setWait(false))
    getUser(infoUser.token)
      .then((res) => {
        setListUser(res.data)
      })
      .catch((err) => console.log(err))
  }, [bool])
  const userEdited = () => {}
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={false} />
        <Header bool={false} />
      </div>
      <div class="page-wrapper">
        <div class="page-content-tab">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <h4 class="page-title">Gestion Entreprises</h4>
                </div>
              </div>
            </div>
           {/*  <div class="row">
              <div class="col-lg-12">
                <div class="d-flex justify-content-between mb-3">
                  <div>
                    <h6>Liste des entreprises</h6>
                  </div>
                  <div class="align-self-center">
                    {(infoUser?.user?.role?.roleType == 'admin' ||
                      infoUser?.user?.role?.roleType == 'opc') && (
                      <>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal">
                          <i
                            data-feather="download"
                            class="align-self-center icon-xs me-2"></i>
                          + Ajouter
                        </button>
                        <AddEnt
                          bool={bool}
                          setBool={setBool}
                          listUser={listUser}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div> */}

            <div class="row" style={{ backgroundColor: 'white',borderRadius:'12px' }}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '30px 20px',
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '20px',
                      fontWeight: '600',
                    }}>
                    Listes des entreprises
                  </span>
                  <span
                    style={{
                      backgroundColor: '#E0E7FF',
                      borderRadius: '24px',
                      padding: '6px 12px',
                      color: '#64748B',
                      fontSize: '14px',
                      fontWeight: '600',
                      marginLeft: '8px',
                      maxHeight: '32px',
                      width: 'auto',
                    }}>
                    {listEnt.length > 0 ? listEnt.length : '0'} Entreprises
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      padding: '8px 12px 8px 12px',
                      backgroundColor: 'rgba(226, 232, 240, 0.6)',
                      borderRadius: '8px',
                      height: '40px',
                    }}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#818CF8"
                        style={{ width: '18px', height: '18px' }}>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </span>
                    <input
                      type="text"
                      placeholder="Recherche"
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                      }}
                      onChange={(ev) => {
                        ev.preventDefault()
                        ev.stopPropagation()
                        const newData = searchOptimizedData(
                          ev.target.value,
                          listEnt
                        )
                        setListEnt(newData)
                      }}
                    />
                  </span>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#myModal"
                    style={{marginLeft:'10px'}}
                    >
                    <i
                      data-feather="download"
                      class="align-self-center icon-xs me-2"></i>
                    + Ajouter
                  </button>
                  <AddEnt bool={bool} setBool={setBool} listUser={listUser} />
                  {/* <button
                    className="btn"
                    style={{
                      backgroundColor: '#4F46E5',
                      color: '#F8FAFC',
                      marginLeft: '24px',
                    }}
                    onClick={(e) => addPlanning(e)}>
                    + Nouvelle tâche
                  </button> */}
                </div>
              </div>
              <div class="col-lg-12">
                <ReactTabulator
                  data={listEnt ? listEnt : []}
                  columns={colonneEnt}
                  layout={'fitColumns'}
                  events={{ cellEdited: entEdited }}
                  options={{
                    locale: true,
                    pagination: 'local',
                    paginationSize: 10,
                    langs: {
                      fr: {
                        pagination: {
                          first: 'Premier',
                          first_title: 'Première page',
                          last: 'Dernier',
                          last_title: 'Dernière page',
                          prev: 'Précédent',
                          prev_title: 'Page précédente',
                          next: 'Suivant',
                          next_title: 'Page suivante',
                          page_size: 'Taille de page',
                          page_title: 'Afficher la page',
                          all: 'Tous',
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {wait && (
        <>
          <div
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
      )}
    </>
  )
}

export default GestionEntreprise
