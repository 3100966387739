import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import UploadFile from './upload/UploadFile'
import { getAllTask } from '../../../helpers/planning/gestionTache/dataGantt'
import { ReactTabulator } from 'react-tabulator'
import { BeatLoader } from 'react-spinners'
import PageNotFound from '../../../components/404/PageNotFound'
import { getAllData } from '../../../helpers/suivi/suivi'
import GestionTache from './GestionTache'
import './dashboard.css'
import axios from 'axios'
import { searchOptimizedData } from '../../../helpers/recherche/recheche'
//Tableau de bord de l'application
const Dashboard = () => {
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [wait, setWait] = useState(false)
  const navigate = useNavigate()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const [havePlanning, setHavePlaning] = useState(false)
  sessionStorage.setItem('media', 'media')
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [allTask, setAllTask] = useState([])
  const [lot, setLot] = useState([])
  const [bool, setBool] = useState(false)
  //Create date picker
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.addEventListener('blur', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  //format type select
  useEffect(() => {
    setWait(true)
    if (infoChantier.planning) {
      setHavePlaning(true)
      getAllData(
        `planning=${infoChantier.planning}`,
        'lots',
        infoUser.token
      ).then((result) => {
        console.log(result.data)
        setLot(result.data)
      })
      getAllTask(infoChantier.planning, infoUser.token)
        .then((res) => {
          setWait(false)
          console.log(res.data.data)
          if (res.data.data) {
            setAllTask(res.data.data)
          }
        })
        .catch((err) => {
          setWait(false)
          console.log(err)
        })
    } else {
      setHavePlaning(false)
      setWait(false)
    }
  }, [havePlanning, bool])

  const formatLot = (cell) => {
    const data = cell.getData()
    const view = document.createElement('select')
    view.className = 'form-select'
    const defaults = document.createElement('option')
    defaults.selected = false
    defaults.innerText = ''
    defaults.value = ''
    view.append(defaults)
    lot &&
      lot.map((element) => {
        const option1 = document.createElement('option')
        option1.value = element._id
        option1.innerText = element.titre
        if (element.titre == cell.getData().lot?.titre) {
          option1.selected = true
        } else {
          option1.selected = false
        }
        view.appendChild(option1)
      })

    view.addEventListener('change', function (e) {
      console.log(e.target.value)
      setWait(true)
      axios
        .patch(
          `${process.env.REACT_APP_URL}/api/v1/taches/${data._id}`,
          { lot: e.target.value },
          {
            headers: {
              Authorization: `Bearer ${infoUser.token}`,
            },
          }
        )
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally((res) => {
          setWait(false)
        })
    })
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }

  const colonneTache = [
    { title: 'ID', field: 'id' },
    { title: 'NOM', field: 'text', editor: 'input' },
    { title: 'DATE DEBUT', field: 'start_date', formatter: date },
    { title: 'DATE FIN', field: 'end_date', formatter: date },
    /*  {title:"AVANCEMENT",field:'progress',editor:'number'}, */
    {
      title: 'LOT',
      formatter: formatLot,
    },
  ]
  const tacheEdited = (cell) => {
    const data = cell.getData()
    console.log(data)
    setWait(true)
    axios
      .patch(
        `${process.env.REACT_APP_URL}/api/v1/taches/${data._id}`,
        { ...data, lot: data.lot?._id },
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally((res) => {
        setWait(false)
      })
  }
  return (
    <>
      <Menu bool={true} />
      <div className="d-flex flex-column">
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid" style={{ paddingTop: '0.5vw' }}>
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <h4 class="page-title">Gestion du planning</h4>
                  {/* <div className="testPin">
                        <span className="pin"></span>
                      </div> */}
                </div>
              </div>
            </div>
            {havePlanning ? (
              <>
                {/* <div className="container mt-2">
                  <h5>Ecraser le planning existant</h5>
                  <UploadFile bool={bool} setBool={setBool} />
                </div> */}
                <div className="row">
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab">
                      <a
                        href="#step1"
                        className="nav-link active"
                        id="step1-tab"
                        data-bs-toggle="tab">
                        Tableau planning
                      </a>
                      <a
                        href="#step2"
                        className="nav-link"
                        id="step2-tab"
                        data-bs-toggle="tab">
                        Planning Gantt
                      </a>
                    </div>
                  </nav>
                  <div className="tab-content" style={{ backgroundColor: 'white',borderRadius:'12px' }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '30px 20px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#334155',
                            fontSize: '20px',
                            fontWeight: '600',
                          }}>
                          Listes des tâches
                        </span>
                        <span
                          style={{
                            backgroundColor: '#E0E7FF',
                            borderRadius: '24px',
                            padding: '6px 12px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                            marginLeft: '8px',
                            maxHeight: '32px',
                            width: 'auto',
                          }}>
                          {allTask.length > 0 ? allTask.length : '0'}{' '}
                          Tâches
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            padding: '8px 12px 8px 12px',
                            backgroundColor: 'rgba(226, 232, 240, 0.6)',
                            borderRadius: '8px',
                            height: '40px',
                          }}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#818CF8"
                              style={{ width: '18px', height: '18px' }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </span>
                          <input
                            type="text"
                            placeholder="Recherche"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onBlur={ (ev) => {
                              ev.preventDefault()
                              ev.stopPropagation()
                              const newData =  searchOptimizedData(
                                ev.target.value,
                                allTask
                              )
                              setAllTask(newData)
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <div
                      className="tab-pane active"
                      id="step1"
                      style={{ width: '100%' }}>
                      <ReactTabulator
                        data={allTask ? allTask : []}
                        columns={colonneTache}
                        layout={'fitColumns'}
                        events={{ cellEdited: tacheEdited }}
                        options={{
                          locale: true,
                          pagination: 'local',
                          paginationSize: 10,
                          langs: {
                            fr: {
                              pagination: {
                                first: 'Premier',
                                first_title: 'Première page',
                                last: 'Dernier',
                                last_title: 'Dernière page',
                                prev: 'Précédent',
                                prev_title: 'Page précédente',
                                next: 'Suivant',
                                next_title: 'Page suivante',
                                page_size: 'Taille de page',
                                page_title: 'Afficher la page',
                                all: 'Tous',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                    <div
                      className="tab-pane"
                      id="step2"
                      style={{ width: '100%' }}>
                      <GestionTache />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="container mt-2">
                  <h5>Importer un nouveau planning</h5>
                  <UploadFile />
                </div>
              </>
            )}
            {/*                 <div
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    right: '0',
                  }}
                >
                  <h1 className="text-center">
                    {' '}
                    <i class="ti ti-arrow-big-down"></i>
                  </h1>
                </div>
 */}{' '}
          </div>
        </div>
      </div>
      {wait && (
        <>
          <div
            style={{
              position: 'fixed',
              width: '100%',
              top: '0',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: '200000000000000',
            }}>
            <div>
              <BeatLoader color="yellowgreen" loading={true} size={25} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Dashboard
