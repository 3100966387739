import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { gantt } from 'dhtmlx-gantt'
import {
  dhtmlxGantt,
  listColumns,
} from '../../../helpers/planning/gestionTache/gantt'
import { getAllData } from '../../../helpers/suivi/suivi'
import { updateOnTask } from '../../../helpers/planning/favori/favori'
import Multiselect from 'multiselect-react-dropdown'
import {
  getAllTask,
  getVersionTask,
} from '../../../helpers/planning/gestionTache/dataGantt'
import BeatLoader from 'react-spinners/BeatLoader'
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css'
import './gestionTache.scss'
import 'dhtmlx-gantt/codebase/api'
import 'dhtmlx-gantt/codebase/dhtmlxgantt'
import axios from 'axios'
import PageNotFound from '../../../components/404/PageNotFound'
import ImportPlanning from './component/ImportPlanning'
import { useToasts } from 'react-toast-notifications'
//Gestion des taches sur dhtmlx
const GestionTache = () => {
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc')
  ) {
    return <PageNotFound />
  }

  // popup
  const [ok, setOk] = useState(false)
  const [currentColumns, setCurrentColumns] = useState([])
  const [originalColumns, setOriginalColumns] = useState([])
  const [taskUpdate, setTaskUpdate] = useState([])
  const [cheminCritique, setChemeinCritique] = useState(true)
  const [general, setGeneral] = useState(true)
  const [observation, setObservation] = useState(false)
  const [correspondance, setCorrespondance] = useState(false)
  const [update, setUpdate] = useState(false)
  const [oneTask, setOneTask] = useState({})
  const [task, setTask] = useState({})
  const [curentEntreprise, setCurrentEntreprise] = useState('')
  const [allLot, setAllLot] = useState([])
  const [newLot, setNewLot] = useState('')
  const [wait, setWait] = useState(false)
  const [zoomPlanning, setZoomPlanning] = useState(
    sessionStorage.getItem('zoomPlanning')
  )
  const [dataGantt, setDataGantt] = useState()
  const [versionGantt, setVersionGantt] = useState([])
  const [bool, setBool] = useState(false)
  const [havePlanning, setHavePlaning] = useState(false)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const filterColonne = (tab1, tab2) => {
    const result = []
    const names = tab2.map((t2) => t2.name)
    console.log('filter')
    console.log(names)
    console.log(tab1)
    console.log(tab2)
    tab1.map((t1) => {
      if (names.includes(t1.name)) {
        result.push(t1)
      }
    })
    return result
  }
  //configuration gantt
  //plugin de dhtmlx
  gantt.plugins({
    auto_scheduling: false,
    critical_path: true,
    //marker: true,
  })
  //configuration
  gantt.config.date_format = '%Y-%m-%d'
  gantt.config.show_errors = false
  gantt.serialize('json')

  //Suppression tache
  gantt.attachEvent('onAfterTaskDelete', function (id, item) {
    console.log(item)
  })

  //auto déplacement des taches
  gantt.config.auto_scheduling = false
  gantt.attachEvent('onGanttReady', function () {
    // Code à exécuter lorsque le Gantt est prêt
    setOriginalColumns(gantt.getGridColumns())
    if (sessionStorage.getItem('colonne')) {
      const newColonne = JSON.parse(sessionStorage.getItem('colonne'))
      setCurrentColumns(newColonne)
    } else {
      setCurrentColumns(gantt.getGridColumns())
    }
    setOriginalColumns(gantt.getGridColumns())
  })
  //formatage date
  gantt.attachEvent('onTaskLoading', function (task) {
    task.planned_start = gantt.date.parseDate(task.planned_start, 'xml_date')
    task.planned_end = gantt.date.parseDate(task.planned_end, 'xml_date')
    return true
  })
  gantt.attachEvent('onColumnResize', function (id, new_width) {
    console.log(gantt.getGridColumns())
  })
  gantt.attachEvent('onGridResize', function (old, new_scale) {
    // your code here
    console.log('manova')
    console.log(new_scale)
  })
  //evenement par double clique
  gantt.attachEvent('onTaskDblClick', function (id, e) {
    setTask(gantt.getTask(id))
    setUpdate(true)
  })

  gantt.attachEvent('onAfterTaskUpdate', function (id, item) {})
  useEffect(() => {
    setCurrentEntreprise(task?.lot?.entreprise?.nom)
  }, [task])

  //Recupération de tous les lots
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots',
      infoUser.token
    ).then((e) => {
      setAllLot(e.data)
    })
  }, [])
  //Recuperation version tache
  useEffect(() => {
    getVersionTask(infoChantier.planning, infoUser.token)
      .then((res) => {
        if (res.data) {
          console.log(res.data)
          console.log(res.data)
          setVersionGantt(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [bool])
  useEffect(() => {
    setWait(true)
    if (infoChantier.planning) {
      setHavePlaning(true)
      getAllTask(infoChantier.planning, infoUser.token).then((e) => {
        console.log(e.data)
        setDataGantt(e)
      })
    } else {
      setHavePlaning(false)
      setChemeinCritique(false)
      setWait(false)
      //gantt.config.highlight_critical_path = false
    }
  }, [bool])
  useEffect(() => {
    console.log(dataGantt)
    if (infoChantier.planning && dataGantt) {
      gantt.serialize('json')
      dhtmlxGantt(gantt, 'gantt1', dataGantt)
      setWait(false)
      gantt.addTaskLayer(function draw_planned(task) {
        if (task.planned_start && task.planned_end) {
          var sizes = gantt.getTaskPosition(
            task,
            task.planned_start,
            task.planned_end
          )
          var el = document.createElement('div')
          el.className = 'baseline'
          el.style.left = sizes.left - 6 + 'px'
          el.style.width = sizes.width + 10 + 'px'
          el.style.height = sizes.height - 13 + 'px'
          el.style.top = sizes.top + 1 + 'px'
          return el
        }
        return false
      })

      gantt.eachTask(function (task) {
        if (task.duration < 1) {
          task.type = gantt.config.types.milestone
        }
        if (+task.parent > 0) {
          var parent = gantt.getTask(task.parent)
          parent.type = gantt.config.types.project
          parent.color = 'lightgray'
        }
      })
      if (sessionStorage.getItem('colonne')) {
        const newColonne = JSON.parse(sessionStorage.getItem('colonne'))
        console.log(filterColonne(gantt.getGridColumns(), newColonne))
        gantt.config.columns = filterColonne(gantt.getGridColumns(), newColonne)
        gantt.render()
      }
    }
  }, [dataGantt])

  //debut baseline
  gantt.config.bar_height = 20
  gantt.config.row_height = 40
  // adding baseline display

  //fonction pour l'affichage des critical path
  const criticalPath = () => {
    if (cheminCritique == false) {
      gantt.config.highlight_critical_path = false
      gantt.render()
    } else {
      gantt.config.highlight_critical_path = true
      gantt.render()
    }
  }

  //formatage date
  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0')
  }

  //fonction pour formater les dates
  const formatDate = (date) => {
    date = new Date(date)
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
  }
  const dateFr = (date) => {
    date = new Date(date)
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/')
  }

  //modification des favori apres mis a jour
  gantt.attachEvent('onTaskClick', function (id, e) {
    var checkbox = gantt.utils.dom.closest(e.target, '.gantt-checkbox-column')
    if (checkbox) {
      checkbox.checked = !!checkbox.checked
      gantt.getTask(id).isCritical = checkbox.checked
      return false
    } else {
      return true
    }
  })
  return (
    <>
      <div className="" style={{ paddingTop: '0.5vw' }}>
        {/* Debut Gantt */}
        <div
          className=""
          style={{ width: '100%', marginTop: '1vw', position: 'relative' }}>
          {havePlanning ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'no-wrap',
                  justifyContent: 'space-between',
                }}>
                <div style={{ width: '60%' }}>
                  <button
                    class="dropdown-toggle btn btn-primary"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Zoom
                    <i className="ti ti-selector"></i>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1">
                    <li
                      onClick={(e) => {
                        e.preventDefault()
                        sessionStorage.setItem('zoomPlanning', 'jour')
                        gantt.config.scales = [
                          { unit: 'day', step: 1, format: '%j %D' },
                        ]
                        gantt.render()
                      }}>
                      <a
                        class="dropdown-item"
                        href="#"
                        style={{ fontSize: '1.2vw' }}>
                        Jours
                      </a>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault()
                        sessionStorage.setItem('zoomPlanning', 'semaine')
                        gantt.config.scales = [
                          { unit: 'month', step: 1, format: '%F %Y' },
                          { unit: 'week', step: 1, format: '%j %D' },
                        ]
                        gantt.render()
                      }}>
                      <a
                        class="dropdown-item"
                        href="#"
                        style={{ fontSize: '1.2vw' }}>
                        Semaine
                      </a>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault()
                        sessionStorage.setItem('zoomPlanning', 'mois')
                        gantt.config.scales = [
                          { unit: 'year', step: 1, format: '%Y' },
                          { unit: 'month', step: 1, format: '%j %F' },
                        ]
                        gantt.render()
                      }}>
                      <a
                        class="dropdown-item"
                        href="#"
                        style={{ fontSize: '1.2vw' }}>
                        Mois
                      </a>
                    </li>
                    {/* <li
                      onClick={(e) => {
                        e.preventDefault()
                        navigate('/Planning_GestionTache_LastPlanner')
                      }}
                    >
                      <a
                        class="dropdown-item"
                        href="#"
                        style={{ fontSize: '1.2vw' }}
                      >
                        Glissant
                      </a>
                    </li> */}
                  </ul>
                  <button
                    class="dropdown-toggle btn btn-primary"
                    type="button"
                    id="dropdownMenuButton5"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ marginLeft: '5px' }}>
                    Filtrer
                    <i className="ti ti-selector"></i>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton5"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}>
                    <li>
                      <a href="#" class="dropdown-item">
                        <Multiselect
                          displayValue="label"
                          onRemove={function noRefCheck(e) {
                            setCurrentColumns(e)
                            console.log(e)
                            sessionStorage.setItem('colonne', JSON.stringify(e))

                            gantt.config.columns = e
                            gantt.render()
                          }}
                          onSearch={function noRefCheck() {}}
                          onSelect={function noRefCheck(e) {
                            console.log(e)
                            setCurrentColumns(e)
                            sessionStorage.setItem('colonne', JSON.stringify(e))
                            gantt.config.columns = e
                            gantt.render()
                          }}
                          options={originalColumns}
                          selectedValues={currentColumns}
                          placeholder="Colonne"
                        />
                      </a>
                    </li>
                  </ul>
                  <button
                    class="dropdown-toggle btn btn-primary"
                    type="button"
                    id="dropdownMenuButton7"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ marginLeft: '5px' }}>
                    Version
                    <i className="ti ti-selector"></i>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton7"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}>
                    {versionGantt ? (
                      <>
                        {versionGantt.map((el) => {
                          return (
                            <>
                              <li
                                style={{
                                  display: 'flex',
                                  marginBottom: '10px',
                                  alignItems: 'center',
                                  padding: '5px',
                                }}>
                                <a href="#" class="dropdown-item">
                                  {`Planning du ${el.date && dateFr(el.date)} ${
                                    el.filename
                                      ? `(.${el.filename.split('.')[1]})`
                                      : ''
                                  }`}
                                </a>
                                <button
                                  className="btn btn-success"
                                  onClick={(ev) => {
                                    ev.preventDefault()
                                    ev.stopPropagation()
                                    if (
                                      confirm(
                                        'Êtes-vous certain(e) de vouloir visionner cette version ? '
                                      )
                                    ) {
                                      console.log(el)
                                      setDataGantt({ data: el.taches })
                                    } else {
                                    }
                                  }}>
                                  Visionner
                                </button>{' '}
                                <button
                                  className="btn btn-danger"
                                  style={{ marginLeft: '5px' }}
                                  onClick={(ev) => {
                                    ev.preventDefault()
                                    ev.stopPropagation()
                                    if (
                                      confirm(
                                        'Êtes-vous certain(e) de vouloir basculer vers cette version ? '
                                      )
                                    ) {
                                      axios
                                        .patch(
                                          `${process.env.REACT_APP_URL}/api/v1/plannings/${el._id}`,
                                          {},
                                          {
                                            headers: {
                                              Authorization: `Bearer ${infoUser.token}`,
                                            },
                                          }
                                        )
                                        .then((res) => {
                                          console.log(res)
                                          setBool(!bool)
                                        })
                                        .catch((err) => {
                                          console.log(err)
                                        })
                                    } else {
                                    }
                                  }}>
                                  Basculer
                                </button>
                                <button
                                  className="btn btn-primary"
                                  style={{ marginLeft: '5px' }}
                                  onClick={(ev) => {
                                    ev.preventDefault()
                                    ev.stopPropagation()
                                    if (
                                      confirm(
                                        'Êtes-vous certain(e) de vouloir télécharger cette version ? '
                                      )
                                    ) {
                                      try {
                                        const url = `${process.env.REACT_APP_URL}/api/v1/backupsTache/file/${el.fileToken}`
                                        const link = document.createElement('a')
                                        link.href = url
                                        document.body.appendChild(link)
                                        link.click()
                                        setBool(!bool)
                                      } catch (err) {
                                        console.log(err)
                                      }
                                    } else {
                                    }
                                  }}
                                  disabled={el.filename ? false : true}>
                                  Télécharger
                                </button>
                              </li>
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <>Aucun ancien planning</>
                    )}
                  </ul>
                  {/* <div className="btn">
                    <span style={{ paddingTop: '2px' }} className="mt-2">
                      Chemin critique :
                    </span>
                  </div>
                  <label
                    class="switch p-1"
                    style={{ marginLeft: '0.5vw', position: 'relative' }}
                    onClick={(e) => {
                      e.preventDefault()
                      setChemeinCritique(!cheminCritique)
                      criticalPath()
                    }}
                  >
                    <input type="checkbox" checked={!cheminCritique} />
                    <span class="slider round"></span>
                  </label> */}

                  <button
                    className="btn btn-success"
                    style={{ marginLeft: '0.5vw' }}
                    onClick={(e) => {
                      addToast('Enregistrement en cours...', {
                        appearance: 'info',
                        autoDismiss: true,
                      })
                      gantt.eachTask(function (task) {
                        taskUpdate.push(task)
                      })
                      console.log(gantt.serialize('json'))
                      axios
                        .patch(
                          `${process.env.REACT_APP_URL}/api/v1/taches`,
                          {
                            planning: infoChantier?.planning,
                            ...gantt.serialize('json'),
                          },
                          {
                            headers: {
                              Authorization: `Bearer ${infoUser.token}`,
                            },
                          }
                        )
                        .then((e) => {
                          setTaskUpdate([])
                          addToast('Enregistrement avec succès .', {
                            appearance: 'success',
                            autoDismiss: true,
                          })
                        })
                        .catch((e) => {
                          //salert("Une erreur c'est produit durant l'enregistrement");
                          setTaskUpdate([])
                          addToast("Une erreur s'est produit.", {
                            appearance: 'error',
                            autoDismiss: true,
                          })
                        })
                    }}>
                    Enregistrer
                  </button>
                  {/* <button onClick={(e)=>{
                        gantt.render()
                       gantt.attachEvent("onBeforeTaskDisplay", function(id, task){
                        if (task.lot){
                            return true;
                        }
                        return false;
                    })
                      }}>Filtrer</button> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    width: '30%',
                  }}>
                  {' '}
                  <button
                    class="dropdown-toggle btn btn-primary"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ marginRight: '20px' }}>
                    Exporter
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1">
                    <li
                      onClick={(e) => {
                        e.preventDefault()
                        var formatter = gantt.ext.formatters.durationFormatter({
                          enter: 'day',
                          store: 'day',
                          format: 'auto',
                        })
                        var linksFormatter = gantt.ext.formatters.linkFormatter(
                          {
                            durationFormatter: formatter,
                          }
                        )
                        gantt.config.columns = [
                          { name: 'id', label: 'ID' },
                          {
                            name: 'text',
                            label: 'Nom',
                            template: function (task) {
                              console.log(task.text)
                              return `${task.text}%`
                            },
                          },
                          { name: 'start_date', label: 'Date début' },
                          { name: 'end_date', label: 'Date Fin' },
                          {
                            name: 'duration',
                            label: 'Durée',
                          },
                          {
                            name: 'progress',
                            label: 'Progression',
                            template: function (task) {
                              return `${(task.progress * 100).toFixed(2)}%`
                            },
                            align: 'center',
                          },
                          {
                            name: 'predecessors',
                            label: 'Predecesseurs',
                            align: 'center',
                            template: function (task) {
                              var links = task.$target
                              var labels = []
                              for (var i = 0; i < links.length; i++) {
                                var link = gantt.getLink(links[i])
                                labels.push(linksFormatter.format(link))
                              }
                              return labels.join(',')
                            },
                          },
                        ]

                        gantt.render()
                        gantt.exportToExcel()
                        setBool(!bool)
                      }}>
                      <a
                        class="dropdown-item"
                        href="#"
                        style={{ fontSize: '1.2vw' }}>
                        Excel
                      </a>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault()
                        var formatter = gantt.ext.formatters.durationFormatter({
                          enter: 'day',
                          store: 'day',
                          format: 'auto',
                        })
                        var linksFormatter = gantt.ext.formatters.linkFormatter(
                          {
                            durationFormatter: formatter,
                          }
                        )
                        gantt.config.columns = [
                          { name: 'id', label: 'ID' },
                          {
                            name: 'text',
                            label: 'Nom',
                            template: function (task) {
                              console.log(task.text)
                              return `${task.text}%`
                            },
                          },
                          { name: 'start_date', label: 'Date début' },
                          { name: 'end_date', label: 'Date Fin' },
                          {
                            name: 'duration',
                            label: 'Durée',
                          },
                          {
                            name: 'progress',
                            label: 'Progression',
                            template: function (task) {
                              return `${(task.progress * 100).toFixed(2)}%`
                            },
                            align: 'center',
                          },
                          {
                            name: 'predecessors',
                            label: 'Predecesseurs',
                            align: 'center',
                            template: function (task) {
                              var links = task.$target
                              var labels = []
                              for (var i = 0; i < links.length; i++) {
                                var link = gantt.getLink(links[i])
                                labels.push(linksFormatter.format(link))
                              }
                              return labels.join(',')
                            },
                          },
                        ]

                        gantt.render()
                        gantt.exportToMSProject()
                        setBool(!bool)
                      }}>
                      <a
                        class="dropdown-item"
                        href="#"
                        style={{ fontSize: '1.2vw' }}>
                        MsProject
                      </a>
                    </li>
                  </ul>
                  <button
                    class="dropdown-toggle btn btn-primary"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#myModal">
                    Importer
                  </button>
                  <ImportPlanning
                    bool={bool}
                    setBool={setBool}
                    setWait={setWait}
                  />
                  {/*  <button
                    class="dropdown-toggle btn btn-primary"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Exporter
                    <i className="ti ti-selector"></i>
                  </button> */}
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1">
                    <li
                      onClick={(e) => {
                        e.preventDefault()
                        gantt.exportToMSProject()
                      }}>
                      <a
                        class="dropdown-item"
                        href="#"
                        style={{ fontSize: '1.2vw' }}>
                        Ms Project
                      </a>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault()
                        //gantt.config.show_grid = false
                        //gantt.config.show_chart = false
                        //BefortExportPDF(gantt)
                        gantt.config.scales = [
                          { unit: 'year', step: 1, format: '%Y' },
                          { unit: 'month', step: 1, format: '%F' },
                        ]
                        gantt.config.columns = [
                          { name: 'text', label: 'Nom', min_width: 180 },
                          { name: 'start_date', label: 'Date début' },
                          { name: 'end_date', label: 'Date Fin' },
                          {
                            name: 'progress',
                            label: 'Progression',
                            template: function (task) {
                              return `${(task.progress * 100).toFixed(2)}%`
                            },
                            align: 'center',
                            width: 70,
                          },
                          {
                            name: 'duration',
                            label: 'Duration',
                            align: 'center',
                            width: 55,
                          },
                        ]
                        gantt.config.show_links = false
                        gantt.exportToPDF({
                          header:
                            '<div class="d-flex justify-content-between"><div class="p-2 bg-info"><h1>KOLOS</h1></div></div>',
                          footer: '<h4>KOLOS</h4>',
                          additional_settings: {
                            format: 'A3',
                            landscape: true,
                            width: '1000',
                            height: '2000',
                          },
                          //   start:"2019-08-28",
                          //   end:"2020-07-17"
                        })

                        setBool(!bool)
                      }}>
                      <a
                        class="dropdown-item"
                        href="#"
                        style={{ fontSize: '1.2vw' }}>
                        PDF
                      </a>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault()
                        gantt.exportToExcel()
                      }}>
                      <a
                        class="dropdown-item"
                        href="#"
                        style={{ fontSize: '1.2vw' }}>
                        Excel
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="gantt1" style={{ width: '100%', height: '75vh' }}></div>
            </>
          ) : (
            <>
              <h1>vous devez importer un planning</h1>
            </>
          )}
        </div>
        {/* Fin Gantt*/}

        {/* debut colonne personnaliser */}
        {update && (
          <>
            <div className="container-fluid contenuRetard">
              <div className="formRetard">
                <div className="container-fluid headerGantt">
                  <h1 className="text-center">Informations de la tâche</h1>
                  <ul class="nav">
                    <li
                      class="nav-item"
                      onClick={(e) => {
                        e.preventDefault()
                        setCorrespondance(false)
                        setObservation(false)
                        setGeneral(true)
                      }}>
                      <a
                        class={
                          general ? 'nav-link bgActive' : 'nav-link bgNonActive'
                        }>
                        GENERAL
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      onClick={(e) => {
                        e.preventDefault()
                        setCorrespondance(true)
                        setObservation(false)
                        setGeneral(false)
                      }}>
                      <a
                        class={
                          correspondance
                            ? 'nav-link bgActive'
                            : 'nav-link bgNonActive'
                        }>
                        CORRESPONDANCE
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      onClick={(e) => {
                        e.preventDefault()
                        setCorrespondance(false)
                        setGeneral(false)
                        setObservation(true)
                      }}>
                      <a
                        class={
                          observation
                            ? 'nav-link bgActive'
                            : 'nav-link bgNonActive'
                        }>
                        OBSERVATIONS
                      </a>
                    </li>
                  </ul>
                </div>
                {general && (
                  <>
                    <form
                      style={{ width: '100%' }}
                      onSubmit={(e) => {
                        e.preventDefault()
                        const a = gantt.getTask(task?.id)
                        gantt.updateTask(task?.id, {
                          ...a,
                          ...{ ...oneTask },
                        })
                        setUpdate(false)
                        setOneTask({})
                      }}>
                      <div
                        className="d-flex"
                        style={{
                          width: '100%',
                          justifyContent: 'space-around',
                        }}>
                        <div class="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label">
                            ID
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={task?.id}
                            style={{ width: '12vw' }}
                            disabled={true}
                          />
                        </div>
                        <div class="mb-3">
                          <label
                            for="exampleFormControlTextarea1"
                            class="form-label">
                            Nom
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            defaultValue={task?.text}
                            style={{ width: '12vw' }}
                            onChange={(e) => {
                              e.preventDefault()
                              setOneTask({
                                ...oneTask,
                                text: e.target.value,
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          width: '100%',
                          justifyContent: 'space-around',
                        }}>
                        <div class="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label">
                            Date début prévue
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            style={{ width: '12vw' }}
                            defaultValue={formatDate(task.planned_start)}
                            disabled={true}
                          />
                        </div>
                        <div class="mb-3">
                          <label
                            for="exampleFormControlTextarea1"
                            class="form-label">
                            Date début réelle
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            style={{ width: '12vw' }}
                            defaultValue={formatDate(task.start_date)}
                            onChange={(e) => {
                              e.preventDefault()
                              setOneTask({
                                ...oneTask,
                                start_date: new Date(e.target.value),
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          width: '100%',
                          justifyContent: 'space-around',
                        }}>
                        <div class="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label">
                            Date fin prévue
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            style={{ width: '12vw' }}
                            defaultValue={formatDate(task.planned_end)}
                            disabled={true}
                          />
                        </div>
                        <div class="mb-3">
                          <label
                            for="exampleFormControlTextarea1"
                            class="form-label">
                            Date fin réelle
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            style={{ width: '12vw' }}
                            defaultValue={formatDate(task.end_date)}
                            onChange={(e) => {
                              e.preventDefault()
                              setOneTask({
                                ...oneTask,
                                end_date: new Date(e.target.value),
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          width: '100%',
                          justifyContent: 'space-around',
                        }}>
                        <div class="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label">
                            Durée Prévue
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            defaultValue={task?.duration}
                            style={{ width: '12vw' }}
                            disabled={true}
                          />
                        </div>
                        <div class="mb-3">
                          <label
                            for="exampleFormControlTextarea1"
                            class="form-label">
                            Durée réelle
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            defaultValue={task?.duration}
                            style={{ width: '12vw' }}
                            disabled={true}
                            onChange={(e) => {
                              e.preventDefault()
                              setOneTask({
                                ...oneTask,
                                duration: +e.target.value,
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          width: '100%',
                          justifyContent: 'space-around',
                        }}>
                        <div class="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label">
                            Avancement
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            defaultValue={
                              task?.progress
                                ? `${(task.progress * 100).toFixed(2)}%`
                                : '0%'
                            }
                            style={{ width: '12vw' }}
                            disabled={true}
                          />
                        </div>
                        <div class="mb-3">
                          <label
                            for="exampleFormControlTextarea1"
                            class="form-label">
                            Date initiale
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            defaultValue={
                              task?.initial_date
                                ? formatDate(task.initial_date)
                                : 'jj/mm/yyyy'
                            }
                            style={{ width: '12vw' }}
                            onChange={(e) => {
                              e.preventDefault()
                              setOneTask({
                                ...oneTask,
                                initial_date: e.target.value,
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          width: '100%',
                          justifyContent: 'space-around',
                        }}></div>

                      <div
                        className="d-flex"
                        style={{
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                        }}>
                        <button className="btn btn-success" type="submit">
                          Enregistrer
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={(e) => {
                            e.preventDefault()
                            setUpdate(false)
                            setOneTask({})
                            gantt.deleteTask(task.id)
                          }}>
                          Supprimer
                        </button>
                        <button
                          className="btn btn-dark"
                          onClick={(e) => {
                            e.preventDefault()
                            setUpdate(false)
                            setOneTask({})
                          }}>
                          Fermer
                        </button>
                      </div>
                    </form>
                  </>
                )}
                {correspondance && (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        updateOnTask(
                          'lot',
                          newLot,
                          task._id,
                          infoUser.token
                        ).then((e) => {
                          console.log(e)
                          setBool(!bool)
                        })
                        //à ne pas supprimer
                        setUpdate(false)
                        setOneTask({})
                      }}>
                      <div
                        class="mb-3"
                        style={{ width: '50%', marginLeft: '5%' }}>
                        <label for="exampleInputEmail1" class="form-label">
                          Lot:
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            const ent = JSON.parse(e.target.value)
                            const datas = {
                              lot: JSON.parse(e.target.value),
                            }
                            setNewLot(datas)
                            setCurrentEntreprise(ent.entreprise.nom)
                            // updateOnTask(
                            //   'lot',
                            //   datas,
                            //   task._id,
                            //   infoUser.token
                            // ).then((e) => {
                            //   console.log(e)
                            //   setBool(!bool)
                            // })
                          }}>
                          <option selected>
                            {task.lot ? task.lot.titre : 'Aucun lot associé'}
                          </option>
                          {allLot &&
                            allLot.map((e) => {
                              return (
                                <>
                                  <option value={JSON.stringify(e)}>
                                    {e.titre}
                                  </option>
                                </>
                              )
                            })}
                        </select>
                      </div>
                      <div
                        class="mb-3"
                        style={{ width: '50%', marginLeft: '5%' }}>
                        <label for="exampleInputPassword1" class="form-label">
                          Entreprise:
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          defaultValue={
                            curentEntreprise
                              ? curentEntreprise
                              : 'Aucune entreprise associée'
                          }
                          disabled={true}
                        />
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                        }}>
                        <button className="btn btn-success" type="submit">
                          Enregistrer
                        </button>

                        <button
                          className="btn btn-dark"
                          onClick={(e) => {
                            e.preventDefault()
                            setUpdate(false)
                            setOneTask({})
                          }}>
                          Fermer
                        </button>
                      </div>
                    </form>
                  </>
                )}
                {observation && (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        const a = gantt.getTask(task?.id)
                        gantt.updateTask(task?.id, {
                          ...a,
                          ...{ ...oneTask },
                        })
                        setUpdate(false)
                        setOneTask({})
                      }}>
                      <div
                        class="mb-3"
                        style={{ width: '50%', marginLeft: '5%' }}>
                        <label for="exampleInputEmail1" class="form-label">
                          Description:
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          defaultValue={task?.favori}
                          onChange={(e) => {
                            e.preventDefault()
                            setOneTask({
                              ...oneTask,
                              favori: e.target.value,
                            })
                          }}
                        />
                      </div>
                      <div
                        class="mb-3"
                        style={{ width: '50%', marginLeft: '5%' }}>
                        <label for="exampleInputPassword1" class="form-label">
                          Observations:
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          defaultValue={
                            task?.observation ? task.observation : ''
                          }
                          onChange={(e) => {
                            e.preventDefault()
                            setOneTask({
                              ...oneTask,
                              observation: e.target.value,
                            })
                          }}
                        />
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                        }}>
                        <button className="btn btn-success" type="submit">
                          Enregistrer
                        </button>
                        <button
                          className="btn btn-dark"
                          onClick={(e) => {
                            e.preventDefault()
                            setUpdate(false)
                            setOneTask({})
                          }}>
                          Fermer
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {/* fin colonne personnaliser */}
      </div>
      {wait && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '40%',
              left: '55%',
              zIndex: '10000',
            }}>
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
      )}
    </>
  )
}

export default GestionTache
