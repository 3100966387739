import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { getAllObservation, saveFiche } from '../../../helpers/rapport/rapport'
import { PencilSquare } from 'react-bootstrap-icons'
import ModifFiche from './ModifFiche'
import BeatLoader from 'react-spinners/BeatLoader'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import PageNotFound from '../../../components/404/PageNotFound'
//page pour la créaction d'un nouveau fiche de visite
const NewFiche = () => {
  if(!localStorage.getItem('isLogin')){
    return <PageNotFound />
  }
  const bool = useSelector((state) => state.bool.bool)
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [fiche, setFiche] = useState([])
  const [wait, setWait] = useState(false)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const  infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)

  const selectAll = (value) => {
    var checkboxes = document.getElementsByTagName('input')
    if (value) {
      setFiche(data)
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type === 'checkbox') {
          checkboxes[i].checked = true
        }
      }
    } else {
      setFiche([])
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type === 'checkbox') {
          checkboxes[i].checked = false
        }
      }
    }
  }
  useEffect(() => {
    setWait(true)
    getAllObservation(infoChantier.planning,infoUser.token).then((e) => {
      console.log(e.data)
      setData(e.data)
      setWait(false)
    })
  }, [bool])
  const validerFiche = () => {
     saveFiche(infoChantier.planning, fiche, false,infoUser.token).then((e) => {
      //console.log(e);
      alert('La fiche de visite a été créée avec succès.')
       navigate('/Rapport_CR')
     })
  }
  const validerFichePhoto = () => {
    saveFiche(infoChantier.planning, fiche, true,infoUser.token).then((e) => {
      //console.log(e);
      alert('La fiche de visite a été créée avec succès.')
     navigate('/Rapport_CR')
    })
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fuild">
            <div class="page-title-box">
              <div class="float-end"></div>
              <h4 class="page-title">Rapport</h4>
            </div>
          </div>
          {/* wizard pour compte rendu et fiche de visite */}
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Nouveau fiche de visite</h4>
                </div>
                <div class="card-body">
                  <nav>
                    <div class="nav nav-tabs" id="nav-tab">
                      <a
                        class="nav-link active"
                        id="step1-tab"
                        data-bs-toggle="tab"
                        href="#step1"
                      >
                        Liste des observations
                      </a>
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane active" id="step1">
                      <div className="d-flex flex-row-reverse mt-3">
                        <button
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal"
                        >
                          Valider
                        </button>
                        <button
                          className="btn btn-dark"
                          style={{ marginRight: '1vw' }}
                          onClick={(e) => {
                            navigate('/Rapport_CR')
                          }}
                        >
                          Retour
                        </button>
                      </div>
                      <table
                        class="table table-striped"
                        style={{ marginTop: '1vw' }}
                      >
                        <thead>
                          <tr>
                            <th>Phase</th>
                            <th>Statut</th>
                            <th>Tags</th>
                            <th>Position</th>
                            <th>Destinataires</th>
                            {/* <th>Description</th> */}
                            <th>Observations</th>
                            <th>
                              {' '}
                              <input
                                type="checkbox"
                                defaultValue={false}
                                onChange={(e) => {
                                  selectAll(e.target.checked)
                                }}
                              />{' '}
                              Selectionner tout
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.map((e) => {
                              return (
                                <>
                                  <tr>
                                    <td>{e.phase ? e.phase : 'EXE'}</td>
                                    <td>{e.statut ? e.statut : '...'}</td>
                                    <td>
                                      {e.tag
                                        ? e.tag.map((tag) => `${tag.value},`)
                                        : '...'}
                                    </td>
                                    <td>{e.position ? e.position : '...'}</td>
                                    <td>
                                      {e.destinataire
                                        ? e.destinataire.map(
                                            (dest) => `${dest.titre},`
                                          )
                                        : '...'}
                                    </td>
                                    {/* <td>
                                    {e.description ? e.description : '...'}
                                  </td> */}
                                    <td>
                                      {e.observation ? e.observation : '...'}
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        id="checkbox"
                                        onChange={(el) => {
                                          if (el.target.checked) {
                                            setFiche([...fiche, e])
                                          } else {
                                            setFiche(
                                              fiche.filter(
                                                (element) =>
                                                  element._id != e._id
                                              )
                                            )
                                          }
                                        }}
                                      />
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* fin wizard */}
          {/* modal pour le choix du format fiche de visite */}
          <div class="modal" id="myModal">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">
                    Choix du format du fiche de visite
                  </h4>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div class="modal-body">
                  <button
                    className="btn btn-primary"
                    data-bs-dismiss="modal" 
                    disabled={true}
                    onClick={(e) => {
                      e.preventDefault()
                      validerFiche()
                    }}
                    style={{ width: '100%' }}
                  >
                    Sans photo
                  </button>{' '}
                  <br />{' '}
                  <button
                    className="btn btn-primary mt-3"
                    data-bs-dismiss="modal"
                    style={{ width: '100%' }}
                    onClick={(e) => {
                      e.preventDefault()
                      validerFichePhoto()
                    }}
                  >
                    Avec photo
                  </button>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-dark"
                    data-bs-dismiss="modal"
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {wait && (
        <>
          <div style={{ position: 'absolute', top: '50%', left: '45%' }}>
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
      )}
    </>
  )
}

export default NewFiche
