import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import Connexion from './pages/connexion/Connexion'
import PassOublier from './pages/connexion/PassOublier'
import Reintialisation_MDP from './pages/connexion/Reintialisation_MDP'
import Home from './pages/Home'
import ChantierTemplate from './pages/template/ChantierTemplate'
import Acceuil from './pages/acceuil/Acceuil'
import PageNotFound from './components/404/PageNotFound'
import ServerNotWork from './components/404/ServerNotWork'
import Dashboard from './pages/chantier/planning/Dashboard'
import GestionTache from './pages/chantier/planning/GestionTache'
import Pointage from './pages/chantier/planning/Pointage'
import Favori from './pages/chantier/planning/Favori'
import Retard from './pages/chantier/planning/Retard'
import Observation from './pages/chantier/observation/Observation'
import Media from './pages/chantier/observation/Media'
import ListeEntreprise from './pages/chantier/suivi/ListeEntreprise'
import Contacte from './pages/chantier/contacte/Contacte'
import Rapport from './pages/chantier/rapport/Rapport'
import Penalite from './pages/chantier/penalite/Penalite'
import ProfilEntreprise from './pages/chantier/suivi/ProfilEntreprise'
import NewCr from './pages/chantier/rapport/NewCr'
import NewFiche from './pages/chantier/rapport/NewFiche'
import DGPF from './pages/chantier/planning/DGPF'
import LastPlanner from './pages/chantier/planning/LastPlanner'
import Profil from './pages/profil/Profil'
import FormatPDF1 from './pages/formatPDF/FormatPDF1'
import OffLine from './pages/chantier/observation/OffLine'
import AddLot from './pages/acceuil/AddLot'
import { store } from './redux'
import GestionEntreprise from './pages/gestionEntreprise/GestionEntreprise'
import GestionUser from './pages/gestionUtilisateur/GestionUser'
import {TaostProvider, ToastProvider} from 'react-toast-notifications'
import NewChantier from './pages/acceuil/NewChantier'
function App() {
  return (
    <ToastProvider>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Connexion />} />
          <Route path="/Add_Chantier" element={<NewChantier />} />
          <Route path="/AddLot" element={<AddLot />} />
          <Route path="/Recuperation_MDP" element={<PassOublier />} />
          <Route path="/Reinitialisation_MDP" element={<Reintialisation_MDP />} />
          <Route path="/chantier" element={<ChantierTemplate />} />
          <Route path="/accueil" element={<Acceuil />} />
          <Route path="/profil" element={<Profil />} />
          <Route path="/ServerNotWork" element={<ServerNotWork />} />
          <Route path="/ChantierTemplate" element={<ChantierTemplate />} />
          <Route path="/Planning_Dashboard" element={<Dashboard />} />
          <Route path="/Planning_GestionTache" element={<GestionTache />} />
          <Route
            path="/Planning_GestionTache_LastPlanner"
            element={<LastPlanner />}
          />
          <Route path="/Planning_Pointage" element={<Pointage />} />
          <Route path="/Planning_Pointage_DGPF" element={<DGPF />} />
          <Route path="/Planning_Favori" element={<Favori />} />
          <Route path="/Planning_Retard" element={<Retard />} />
          <Route path="/Observation_Plan" element={<Observation />} />
          <Route path="/Observation_OffLine" element={<OffLine />} />
          <Route path="/Observation_Media/:_id" element={<Media />} />
          <Route path="/SuiviOperationnel" element={<ListeEntreprise />} />
          <Route
            path="/SuiviOperationnel_Profil"
            element={<ProfilEntreprise />}
          />
          <Route path="/Contact" element={<Contacte />} />
          <Route path="/Rapport_CR" element={<Rapport />} />
          <Route path="/Rapport_NewCR" element={<NewCr />} />
          <Route path="/Rapport_NewFiche" element={<NewFiche />} />
          <Route path="/Penalite" element={<Penalite />} />
          <Route path="/Format" element={<FormatPDF1 />} />
          <Route path="/Gestion_Entreprises" element={<GestionEntreprise />} />
          <Route path="/Gestion_Utilisateurs" element={<GestionUser />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </Provider>
    </ToastProvider>
  )
}

export default App
