import axios from "axios"
export const getPresence =  async (token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/presences`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const updateCr =  async (id,data,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/comptesrendu/${id}`,{
            archive : data
        },config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const updatefiche =  async (id,data,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/visites/${id}`,{
            archive : data
        },config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const updateObservation =  async (data,token,planning) => {
    console.log(data);
    const config = {
        headers: {
        'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      }
    const formData = new FormData()
    formData.append('archive', data.archive ? data.archive :false)
    formData.append('top', data.top ? data.top : 0)
    formData.append('left', data.left ? data.left : 0)
    formData.append('description', data.description)
    formData.append('phase', data.phase)
    formData.append('itemNumber', data.itemNumber)
    formData.append('aLeverLe', data.alever ? data.alever : new Date(Date.now()))
    formData.append('statut', data.status ? data.status : 'designe')
    formData.append('tag', JSON.stringify(data.tag))
    formData.append('position', data.position)
    formData.append('destinataire', JSON.stringify(data.destinataire))
    formData.append('observation', data.observation)
    formData.append('type', data.type)
    formData.append('avancementPrevu',data.avancementPrevu ? data.avancementPrevu : 0)
    formData.append('avancementActuel',data.avancementActuel ? data.avancementActuel : 0)
    formData.append('alreadySent',data.alreadySent)
    if (data.uuid) {
      formData.append('uuid', data.uuid)
    }
    formData.append('_id', data._id)
    formData.append('taches', JSON.stringify(data.taches))
    
    try{
        const result = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/ressources/${data.mediaId}?planning=${planning}`,formData,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}

export const getAllCR =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/comptesrendu?planning=${planning}&sort=-idCR&limit=10000000`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getAnnexe =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/annexes?planning=${planning}&sort=-idLastCr&limit=1000`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}


export const getAllFiche =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/visites?planning=${planning}&sort=-idVisite&limit=1000`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}

export const getAllEntreprise =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/lots/lotProfile?planning=${planning}`,config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getAllObservation =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/ressources/allPins/?planning=${planning}&mediaType=pdf`,config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const saveFiche =  async (planning,fiche,query,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.post(`${process.env.REACT_APP_URL}/api/v1/visites?avecPhoto=${query}`,{visites:fiche,planning:planning},config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}