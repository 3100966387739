import React, { useEffect, useState } from 'react'
import { ReactTabulator } from 'react-tabulator'
import axios from 'axios'
const AssignerTache = ({ id,allTask,bool,bool1,setBool}) => {
  const infoUsers = localStorage.getItem('users')
  const [listTache,setListTache] = useState([])
  const infoUser = JSON.parse(infoUsers)
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.disabled = true
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.addEventListener('blur', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }

  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = data.lot?._id==id ? true : false
     input.addEventListener('change', function (e) {
      e.preventDefault()
      console.log(e.target.checked);
      if(e.target.checked){
        axios
      .patch(
        `${process.env.REACT_APP_URL}/api/v1/taches/${data._id}`,
        { lot: id },
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
      }else{
         axios
      .patch(
        `${process.env.REACT_APP_URL}/api/v1/taches/${data._id}`,
        { lot: null },
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
      }
    }) 
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }
  const colonneTache = [
    { title: 'ID', field: 'id' },
    { title: 'NOM', field: 'text' },
    { title: 'DATE DEBUT',width:145, field: 'start_date',formatter:date },
    { title: 'DATE FIN',width:145, field: 'end_date',formatter:date},
    /* {
      title: 'DUREE',
      field: 'duration',
    }, */
    {
      title: 'LOT',
      field:'lot.titre'
    },
    {
      title:'',
      formatter:checkbox
    }
  ]
  const assignementEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'statutLot') {
      console.log(data.statutLot);
    } else {
      
    }
  }

  useEffect(()=>{
    setListTache(allTask.map((el)=>{
      if(el.lot?._id==localStorage.getItem("idAssignement")){
        el.statutLot = true
        return el
      }else{
        el.statutLot=false
        return el
      }
    }))
  },[bool1])
  return (
    <div class="modal " id={`myModal1${id}`}>
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Assigner</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              onClick={(ev)=>{
                setBool(!bool)
              }}
            ></button>
          </div>
          <form
            onSubmit={(ev) => {
              ev.preventDefault()
              ev.target.reset()
            }}
          >
            <div class="modal-body">
              <div className="container-fluid mt-5">
                <h6>Liste des taches</h6>
                <ReactTabulator
                  data={listTache ? listTache : []}
                  columns={colonneTache}
                  layout={'fitColumns'}
                  events={{cellEdited:assignementEdited}}
                  options={{
                    locale: true,
                    pagination: 'local',
                    paginationSize: 10,
                    langs: {
                      fr: {
                        pagination: {
                          first: 'Premier',
                          first_title: 'Première page',
                          last: 'Dernier',
                          last_title: 'Dernière page',
                          prev: 'Précédent',
                          prev_title: 'Page précédente',
                          next: 'Suivant',
                          next_title: 'Page suivante',
                          page_size: 'Taille de page',
                          page_title: 'Afficher la page',
                          all: 'Tous',
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>

            <div class="modal-footer">
               <button
                type="submit"
                class="btn btn-success"
                data-bs-dismiss="modal"
                onClick={(ev)=>{
                  setBool(!bool)
                }}
              >
                Enregistrer
              </button> 
              <button
                type="button"
                class="btn btn-dark"
                data-bs-dismiss="modal"
                onClick={(ev)=>{
                  setBool(!bool)
                }}
              >
                Fermer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AssignerTache
