import React, {useState} from 'react'
import axios from 'axios'
import { updateLot } from '../../../../helpers/suivi/suivi'
import { useToasts } from 'react-toast-notifications'
const ModifLot = ({ id, lot, allEntreprise, bool, setBool }) => {
  const { addToast } = useToasts()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [nom, setNom] = useState(lot.titre ? lot.titre : 'Non défini')
  const [titre, setTitre] = useState(lot.sousTitre ? lot.sousTitre  : "Non défini")
  const [description, setDescription] = useState(lot.description ? lot.description :'Non défini')
  const [couleur, setCouleur] = useState(lot.couleur ? lot.couleur : '#CCCCCC')
  const [entreprise, setEntreprise] = useState(lot.entreprise?._id ? lot.entreprise._id : "")
  const [logo,setLogo] = useState(lot.photoLot ? lot.photoLot : "")
  const saveModif = ()=>{
    const formData = new FormData()
    formData.append('titre',nom)
    formData.append('sousTitre',titre)
    formData.append('description',description)
    formData.append('couleur',couleur)
    formData.append('entreprise',entreprise)
    formData.append('photoLot',logo)
    updateLot(lot._id,formData,infoUser.token).then((res)=>{
      addToast('Lot modifié avec succès', {
        appearance: 'success',
        autoDismiss: true,
      })
      console.log(res);
      setBool(!bool)
    }).catch((err)=>{
      addToast("Une erreur s'est produit", {
        appearance: 'error',
        autoDismiss: true,
      })
      console.log(err);
    })
  }
  return (
    <>
      <div class="modal" id={`myModal${id}`}>
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">
                Modification du lot {lot.titre ? lot.titre : ''}
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form onSubmit={(ev)=>{
              ev.preventDefault()
              saveModif()
            }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label class="form-label">Nom du lot:</label>
                  <input
                    type="text"
                    defaultValue={lot.titre ? lot.titre : ''}
                    class="form-control"
                    onChange={(champ)=>{
                      setNom(champ.target.value)
                    }}
                  />
                </div>
                <div class="mb-3 mt-3">
                  <label class="form-label">Titre du lot:</label>
                  <input
                    type="text"
                    defaultValue={lot.sousTitre ? lot.souTitre : ''}
                    class="form-control"
                    onChange={(champ)=>{
                      setTitre(champ.target.value)
                    }}
                  />
                </div>
                <div class="mb-3 mt-3">
                  <label class="form-label">Description du lot:</label>
                  <input
                    type="text"
                    defaultValue={lot.description ? lot.description : ''}
                    class="form-control"
                    onChange={(champ)=>{setDescription(champ.target.value)}}
                  />
                </div>
                <div class="mb-3 mt-3">
                  <label class="form-label">Entreprise:</label>
                  <select class="form-select" onChange={(champ)=>{setEntreprise(champ.target.value)}}>
                    {!lot.entreprise && (<><option value="">Sélectionner une entreprise</option></>)}
                    {allEntreprise &&
                      allEntreprise.map((ent) => {
                        return (
                          <>
                            <option
                              value={ent._id}
                              selected={
                                ent._id == lot.entreprise?._id ? true : false
                              }
                            >
                              {ent.nom}
                            </option>
                          </>
                        )
                      })}
                  </select>
                </div>
                {/* <div class="mb-3 mt-3">
                  <label class="form-label">Logo:</label>
                  <input
                    type="file"
                    accept='image/*'
                    class="form-control form-control-color"
                    onChange={(champ)=>{
                      setLogo(champ.target.files[0])
                    }}
                  />
                </div> */}
               {/*  <div class="mb-3 mt-3">
                  <label class="form-label">Couleur:</label>
                  <input
                    type="color"
                    class="form-control form-control-color"
                    defaultValue={lot.couleur ? lot.couleur : '#CCCCCC'}
                  />
                </div> */}
              </div>

              <div class="modal-footer">
                  <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button> 
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModifLot
