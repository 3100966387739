import axios from 'axios'

export const getAllEntreprise = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/entreprises`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    alert('le serveur ne repond pas')
    return err
  }
}
export const addLot = async (data,token) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  }
  try {
    const result = await axios
      .post(`${process.env.REACT_APP_URL}/api/v1/lots`,data,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    alert('le serveur ne repond pas')
    return err
  }
}
export const updateLot = async (id,data,token) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  }
  try {
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/lots/${id}`,data,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    alert('le serveur ne repond pas')
    return err
  }
}
export const updateEnt = async (id,data,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  try {
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/entreprises/${id}`,data,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    alert('le serveur ne repond pas')
    return err
  }
}




export const getAllUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/users`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    alert('le serveur ne repond pas')
    return err
  }
}
export const getForfait = async (planning,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/forfaits?planning=${planning}`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    alert('le serveur ne repond pas')
    return err
  }
}
export const updateForfait = async (id, data,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    //console.log(data._id)
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/forfaits/${id}`,data,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}


export const addData = async (candidate, path,id,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_URL}/api/v1/${path}`,{
       ...candidate,
        lot:id
      },
      config
    )
    return data.data
  } catch (err) {
    console.log(err)
    return { message: 'erreur' }
  }
}
export const updateData = async (data, path,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    switch(path){
      case 'entreprises':
        const result = await axios
         .patch(`${process.env.REACT_APP_URL}/api/v1/${path}/${data._id}`,{nom:data.nom},config)
         .then((e) => {
          return e.data
         })
          .catch((e) => {
          return e
         })
         return result
      case 'lots':
         console.log(data);
          const resultt = await axios
           .patch(`${process.env.REACT_APP_URL}/api/v1/${path}/${data._id}`,{titre:data.lot},config)
           .then((e) => {
            return e.data
           })
            .catch((e) => {
            return e
           })
           return resultt
      default : 
        const results = await axios
        .patch(`${process.env.REACT_APP_URL}/api/v1/${path}/${data._id}`, data,config)
        .then((e) => {
          return e.data
        })
        .catch((e) => {
         return e
       })
         return results
    }
  } catch (err) {
    console.log(err)
    return err
  }
}

export const getAllData = async (query, path,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/${path}?${query}`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

export const getData = async (id, path,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_URL}/api/v1/${path}/${id}`,config
    )
    return data.data
  } catch (err) {
    console.log(err)
    return err
  }
}
export const getDataPresence = async (id,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_URL}/api/v1/users/userParChantier?planning=${id}`,config
    )
    return data.data
  } catch (err) {
    console.log(err)
    return err
  }
}


export const delData = async (data, path,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    //console.log(data._id)
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/${path}/${data._id}`,{archive:true},config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

export const updateDoc = async (id, data,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    //console.log(data._id)
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/suiviDoc/updateSingle?id=${id}`,data,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}
export const addDoc = async (id, data,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    //console.log(data._id)
    const result = await axios
      .post(`${process.env.REACT_APP_URL}/api/v1/suiviDoc`,{
        document:id,
        planning:data
      },config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}


export const getAllRole = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/roles?limit=10000`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

