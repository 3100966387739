import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import Chart from 'react-apexcharts'
import BeatLoader from 'react-spinners/BeatLoader'
import { ReactTabulator } from 'react-tabulator'
import { useToasts } from 'react-toast-notifications'
import {
  getAllData,
  getData,
  addData,
  updateData,
  delData,
  updateDoc,
  addDoc,
} from '../../../helpers/suivi/suivi'
import ModalDocument from './component/ModalDocument'
import PageNotFound from '../../../components/404/PageNotFound'
import { useNavigate } from 'react-router-dom'
//Profil de chaque entreprise
const ProfilEntreprise = () => {
  const { addToast } = useToasts()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const navigate = useNavigate()
  //const _id = localStorage.getItem('_id')
  //const titreLot = localStorage.getItem('titreLot')
  const [_id, setId] = useState(
    localStorage.getItem('_id') ? localStorage.getItem('_id') : ''
  )
  const [_idLot, setIdLot] = useState(
    localStorage.getItem('_idLot') ? localStorage.getItem('_idLot') : ''
  )
  const [titreLot, setTitreLot] = useState(
    localStorage.getItem('titreLot') ? localStorage.getItem('titreLot') : ''
  )
  const [dataEngagement, setDataEngagement] = useState([])
  const [dataAdministratif, setDataAdministratif] = useState([])
  const [data, setData] = useState([])
  const [dataDocument, setDataDocument] = useState([])
  const [dataSecurite, setDataSecurite] = useState([])
  const [dataInstallation, setDataInstallation] = useState([])
  const [dataPlanningSo, setDataPlanningSo] = useState([])
  const [dataModalPlanification, setDataModalPlanification] = useState({})
  const [dataModalEngagement, setDataModalEngagement] = useState({})
  const [dataModalAdministration, setDataModalAdministration] = useState({})
  const [dataModalDocument, setDataModalDocument] = useState({})
  const [dataModalSecurite, setDataModalSecurite] = useState({})
  const [dataModalInstallation, setDataModalInstallation] = useState({})
  const [dataModalSuiviDoc, setDataModalSuiviDoc] = useState([])
  const [entreprise, setEntreprise] = useState()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [refresh, setRefresh] = useState(true)
  const [wait, setWait] = useState(false)
  const indiceDoc = [
    { value: 'A' },
    { value: 'B' },
    { value: 'C' },
    { value: 'D' },
    { value: 'E' },
    { value: 'F' },
    { value: 'G' },
    { value: 'H' },
    { value: 'I' },
    { value: 'J' },
    { value: 'K' },
    { value: 'L' },
    { value: 'M' },
    { value: 'N' },
    { value: 'O' },
    { value: 'P' },
    { value: 'Q' },
    { value: 'R' },
    { value: 'S' },
    { value: 'T' },
    { value: 'U' },
    { value: 'V' },
    { value: 'W' },
    { value: 'X' },
    { value: 'Y' },
    { value: 'Z' },
  ]
  const [options, setOptions] = useState({
    plotOptions: {
      radialBar: {
        dataLabels: {
          show: true,
          total: {
            show: true,
            label: 'FF',
          },
          hover: {
            offsetY: -10,
            fontSize: '20px',
            color: '#ffffff',
          },
        },
      },
    },
    labels: [
      'Engagements',
      'Administratifs',
      'Documents',
      'Sécurités',
      'Installation',
    ],
  })
  const [series, setSeries] = useState([0, 0, 0, 0, 0, 0])
  useEffect(() => {
    setId(localStorage.getItem('_id'))
    setTitreLot(localStorage.getItem('titreLot'))
    getAllData(`entId=${_id}`, 'lots/lotprogress', infoUser.token).then((e) => {
      console.log(e)
      if (e.data.pourcentage) {
        setSeries(e.data.pourcentage)
        setOptions({
          plotOptions: {
            radialBar: {
              dataLabels: {
                show: true,
                total: {
                  show: true,
                  label: e.data.note,
                  formatter: function () {
                    return e.data.pourcentageGlobal
                      ? `${e.data.pourcentageGlobal.toFixed(0)} %`
                      : 0
                  },
                },
                hover: {
                  offsetY: -10,
                  fontSize: '20px',
                  color: '#ffffff',
                },
              },
            },
          },
          labels: [
            'Planning',
            'Pannification',
            'Engagements',
            'Administratifs',
            'Documents',
            'Sécurités',
            'Installation',
          ],
        })
      } else {
        addToast("Une erreur s'est produit", {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    })
    getData(_id, 'entreprises', infoUser.token).then((e) => {
      console.log(e)
      setEntreprise(e)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}`,
      'planningSo',
      infoUser.token
    ).then((e) => {
      setDataPlanningSo(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}`,
      'engagements',
      infoUser.token
    ).then((e) => {
      setDataEngagement(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}`,
      'administratifs',
      infoUser.token
    ).then((e) => {
      setDataAdministratif(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}`,
      'documents',
      infoUser.token
    ).then((e) => {
      setDataDocument(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}`,
      'securites',
      infoUser.token
    ).then((e) => {
      setDataSecurite(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}`,
      'installationchantiers',
      infoUser.token
    ).then((e) => {
      setDataInstallation(e.data)
    })
  }, [_id, titreLot])
  //formatage date
  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0')
  }

  //fonction pour formater les dates
  const formatDate = (date) => {
    date = new Date(date)
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
  }
  //fonction pour verifier indice unique
  const indiceUnique = (tab, value) => {
    const tableau = tab.map((el) => {
      return el.indiceEnCours
    })
    if (tableau.includes(value)) {
      return true
    } else {
      return false
    }
  }
  //del Function
  const pop = (candidate, data) => {
    return data.filter((d) => d._id !== candidate._id)
  }

  const delPlanning = async (cell) => {
    try {
      await delData(cell.getData(), 'planningSo', infoUser.token)
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataPlanningSo([...pop(cell.getData(), dataPlanningSo)])
    //cell.getRow().delete()
  }

  const delEngagements = async (cell) => {
    try {
      await delData(cell.getData(), 'engagements', infoUser.token)
    } catch (err) {
      aaddToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataEngagement([...pop(cell.getData(), dataEngagement)])
    console.log(cell.getData()._id)
    //cell.getRow().delete()
  }

  const delAdministratifs = async (cell) => {
    try {
      await delData(cell.getData(), 'administratifs', infoUser.token)
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataAdministratif([...pop(cell.getData(), dataAdministratif)])
    //cell.getRow().delete()
  }

  const delDocuments = async (cell) => {
    try {
      await delData(cell.getData(), 'documents', infoUser.token)
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataDocument([...pop(cell.getData(), dataDocument)])
    //cell.getRow().delete()
  }

  const delSecurites = async (cell) => {
    try {
      await delData(cell.getData(), 'securites', infoUser.token)
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataSecurite([...pop(cell.getData(), dataSecurite)])
    //cell.getRow().delete()
  }

  /*const delPenalites = async (cell)=>{
    try{
      await delData(cell.getData(), 'securites')
    }catch(err){alert(err)}
  }*/

  const delInstallationChantier = async (cell) => {
    try {
      await delData(cell.getData(), 'installationchantiers', infoUser.token)
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataInstallation([...pop(cell.getData(), dataInstallation)])
    //cell.getRow().delete()
  }

  //Boutton de suppression
  const button = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-trash')

    //create delete button
    var delBt = document.createElement('button')
    delBt.type = 'button'
    delBt.appendChild(delIcon)
    delBt.classList.add('btn')
    delBt.classList.add('btn-outline-danger')
    delBt.addEventListener('click', function () {
      delFunction(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(delBt)

    return buttonHolder
  }

  //Modal document
  const modalDocument = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalDoc')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      console.log(cell.getData())
      setDataModalDocument(cell.getData())
      setDataModalSuiviDoc(
        cell.getData().suiviDocs ? cell.getData().suiviDocs : []
      )
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }
  //Modal Plannification
  const modalPlannification = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalPlannification')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalPlanification(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Engagement
  const modalEngagement = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEngagement')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalEngagement(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Administratif
  const modalAdministratif = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalAdministratif')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalAdministration(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Securite
  const modalSecurite = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalSecurite')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalSecurite(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Installation
  const modalInstallation = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalInstallation')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalInstallation(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }

  //Create date picker
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.addEventListener('blur', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }

  const inputUrgence = (cell) => {
    const option1 = document.createElement('option')
    option1.value = 1
    option1.innerText = 'Faible'
    if (option1.value == cell.getValue()) {
      option1.selected = true
    }
    const option2 = document.createElement('option')
    option2.value = 2
    option2.innerText = 'Important'
    if (option2.value == cell.getValue()) {
      option2.selected = true
    }
    const option3 = document.createElement('option')
    option3.value = 3
    option3.innerText = 'Primordial'
    if (option3.value == cell.getValue()) {
      option3.selected = true
    }
    const defaults = document.createElement('option')
    defaults.selected = true
    defaults.innerText = cell.getValue() ? cell.getValue() : 0
    defaults.value = cell.getValue() ? cell.getValue() : 0
    const view = document.createElement('select')
    view.className = 'form-select'
    view.appendChild(option1)
    view.appendChild(option2)
    view.appendChild(option3)
    view.addEventListener('blur', function (e) {
      console.log(e.target.value)
      cell.setValue(e.target.value)
    })
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  const Visa = (cell) => {
    const option1 = document.createElement('option')
    option1.value = 'BPE'
    option1.innerText = 'BPE'
    if (option1.value == cell.getValue()) {
      option1.selected = true
    }
    const option2 = document.createElement('option')
    option2.value = 'VSO'
    option2.innerText = 'VSO'
    if (option2.value == cell.getValue()) {
      option2.selected = true
    }
    const option3 = document.createElement('option')
    option3.value = 'VAO'
    option3.innerText = 'VAO'
    if (option3.value == cell.getValue()) {
      option3.selected = true
    }
    const option4 = document.createElement('option')
    option4.value = 'VAO-B'
    option4.innerText = 'VAO-B'
    if (option4.value == cell.getValue()) {
      option4.selected = true
    }
    const option5 = document.createElement('option')
    option5.value = 'REF'
    option5.innerText = 'REF'
    if (option5.value == cell.getValue()) {
      option5.selected = true
    }
    const defaults = document.createElement('option')
    defaults.selected = true
    defaults.innerText = cell.getValue() ? cell.getValue() : ''
    defaults.value = cell.getValue() ? cell.getValue() : ''
    const view = document.createElement('select')
    view.className = 'form-select'
    view.appendChild(option1)
    view.appendChild(option2)
    view.appendChild(option3)
    view.appendChild(option4)
    view.appendChild(option5)
    view.addEventListener('blur', function (e) {
      console.log(e.target.value)
      cell.setValue(e.target.value)
    })
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }

  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
      if (cell.getValue()) {
        console.log('ok')
        if (
          (data.prevuLe == undefined) |
          (data.prevuLe == '') |
          (data.prevuLe == null)
        ) {
          data.prevuLe = new Date(Date.now()).toISOString()
        }
        if (
          (data.faitLe == undefined) |
          (data.faitLe == '') |
          (data.faitLe == null)
        ) {
          data.faitLe = new Date(Date.now()).toISOString()
        }
        console.log(data)
      } else {
      }
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }
  const colonneEngagement = [
    { title: 'Engagements', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Acté Le', field: 'engageLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Urgence', field: 'urgence', formatter: inputUrgence },
    { title: 'Tenue', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', width: 100, formatter: modalEngagement() },
    { title: 'Archive', field: 'archive', width: 100, formatter: checkbox },
    /* {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delEngagements),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    }, */
  ]
  const colonneAdministratif = [
    { title: 'Titre', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', width: 100, formatter: modalAdministratif() },
    { title: 'Archive', field: 'archive', width: 100, formatter: checkbox },
   /*  {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delAdministratifs),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    }, */
  ]
  const colonneDocument = [
    { title: 'Titre', field: 'titre', editor: 'input' },
    { title: 'Phase', field: 'phase', editor: 'input' },
    { title: 'Type', field: 'type', editor: 'input' },
    { title: 'Niveau', field: 'niveau', editor: 'input' },
    { title: 'Zone', field: 'zone', editor: 'input' },
    { title: 'Indice', field: 'indice' },
    { title: 'Date BPE', field: 'dateBPE', formatter: date },
    { title: 'Prévu Le', field: 'prevision', formatter: date },
    { title: 'Diffusé Le', field: 'diffusion', formatter: date },
    { title: 'Visa', field: 'visa' },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'functionButton',
      formatter: modalDocument(),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
    { title: 'Archive', field: 'archive', width: 100, formatter: checkbox },
   /*  {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delDocuments),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    }, */
  ]
  const colonnePlanning = [
    { title: 'Tâches', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Acté Le', field: 'acteLe', formatter: date },
    { title: 'Planifié Le', field: 'prevuLe', formatter: date },
    { title: 'Réalisé Le', field: 'faitLe', formatter: date },
    { title: 'Urgence', field: 'urgence', formatter: inputUrgence },
    { title: 'Tenue', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', with: 100, formatter: modalPlannification() },
    { title: 'Archive', field: 'archive', width: 100, formatter: checkbox },
    /* {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delPlanning),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    }, */
  ]
  const colonneSecurite = [
    { title: 'Remarques', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Urgence', field: 'urgence', formatter: inputUrgence },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', width: 100, formatter: modalSecurite() },
    { title: 'Archive', field: 'archive', width: 100, formatter: checkbox },
    /* {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delSecurites),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    }, */
  ]
  const colonneInstallation = [
    { title: 'Remarques', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Urgence', field: 'urgence', formatter: inputUrgence },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', width: 100, formatter: modalInstallation() },
    { title: 'Archive', field: 'archive', width: 100, formatter: checkbox },
    /* {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delInstallationChantier),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    }, */
  ]
  const rowDataCandidate = {
    titre: 'New',
    noteLe: Date.now(),
    entreprise: _id,
    planning: infoChantier.planning,
  }
  const rowPlanningCandidate = {
    titre: 'New',
    urgence: 1,
    statut: false,
  }
  const planningEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataPlanningSo(
          dataPlanningSo.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `planningSo`, infoUser.token)
      } else {
        data.statut = true
        setDataPlanningSo(
          dataPlanningSo.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `planningSo`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataPlanningSo(
              dataPlanningSo.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `planningSo`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataPlanningSo(
            dataPlanningSo.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `planningSo`, infoUser.token)
        }
      } else {
        updateData(data, `planningSo`, infoUser.token)
      }
    }
  }
  const engagementEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataEngagement(
          dataEngagement.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `engagements`, infoUser.token)
      } else {
        data.statut = true
        setDataEngagement(
          dataEngagement.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `engagements`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataEngagement(
              dataEngagement.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `engagements`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataEngagement(
            dataEngagement.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `engagements`, infoUser.token)
        }
      } else {
        updateData(data, `engagements`, infoUser.token)
      }
    }
  }

  const administratifEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataAdministratif(
          dataAdministratif.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `administratifs`, infoUser.token)
      } else {
        data.statut = true
        setDataAdministratif(
          dataAdministratif.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `administratifs`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataAdministratif(
              dataAdministratif.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `administratifs`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataAdministratif(
            dataAdministratif.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `administratifs`, infoUser.token)
        }
      } else {
        updateData(data, 'administratifs', infoUser.token)
      }
    }
  }
  const documentEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.diffusion == '') |
        (data.diffusion == undefined) |
        (data.diffusion == null)
      ) {
        data.statut = false
        setDataDocument(
          dataDocument.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `documents`, infoUser.token).then((e) => {})
      } else {
        data.statut = true
        setDataDocument(
          dataDocument.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `documents`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.diffusion == '') |
            (data.diffusion == undefined) |
            (data.diffusion == null)
          ) {
            data.diffusion = new Date(Date.now()).toISOString()
            setDataDocument(
              dataDocument.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `documents`, infoUser.token)
          }
        } else {
          data.diffusion = ''
          setDataDocument(
            dataDocument.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `documents`, infoUser.token)
        }
      } else {
        setDataDocument(
          dataDocument.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `documents`, infoUser.token)
      }
    }
  }
  const securiteEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataSecurite(
          dataSecurite.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `securites`, infoUser.token)
      } else {
        data.statut = true
        setDataSecurite(
          dataSecurite.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `securites`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataSecurite(
              dataSecurite.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `securites`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataSecurite(
            dataSecurite.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `securites`, infoUser.token)
        }
      } else {
        updateData(data, `securites`, infoUser.token)
      }
    }
  }
  const installationEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataInstallation(
          dataInstallation.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `installationchantiers`, infoUser.token)
      } else {
        data.statut = true
        setDataInstallation(
          dataInstallation.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `installationchantiers`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataInstallation(
              dataInstallation.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `installationchantiers`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataInstallation(
            dataInstallation.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `installationchantiers`, infoUser.token)
        }
      } else {
        updateData(data, `installationchantiers`, infoUser.token)
      }
    }
  }
  const addPlanning = async (e) => {
    e.preventDefault()
    const result = await addData(
      rowDataCandidate,
      'planningSo',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setDataPlanningSo([{ ...result }, ...dataPlanningSo])
  }
  const addEngagementRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      rowDataCandidate,
      'engagements',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setDataEngagement([{ ...result }, ...dataEngagement])
  }
  const addAdministratifRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      rowDataCandidate,
      'administratifs',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setDataAdministratif([{ ...result }, ...dataAdministratif])
  }
  const addDocumentRow = async (e) => {
    e.preventDefault()
    setWait(true)
    await addData(rowDataCandidate, 'documents', _idLot, infoUser.token).then(
      (e) => {
        console.log(e)
        if (e.name == 'AxiosError' || e.message == 'erreur') {
          setWait(false)
          addToast("Une erreur s'est produit.", {
            appearance: 'error',
            autoDismiss: true,
          })
        } else {
          setWait(false)
          setDataDocument([{ ...e }, ...dataDocument])
        }
      }
    )
  }
  const addSecuriteRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      rowDataCandidate,
      'securites',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setDataSecurite([{ ...result }, ...dataSecurite])
  }
  const addInstallationRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      rowDataCandidate,
      'installationchantiers',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setDataInstallation([{ ...result }, ...dataInstallation])
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div class="page-title-box">
              <div class="float-end"></div>
              <h4 class="page-title">Suivi opérationnel</h4>
            </div>
            <div class="page-title-box">
              <div class="float-end">
                <button
                  className="btn btn-dark"
                  onClick={(e) => {
                    navigate('/SuiviOperationnel')
                  }}
                >
                  Retour
                </button>
              </div>
              <h6 class="page-title">Profil</h6>
            </div>
            {/*debut profil */}
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="met-profile">
                      <div class="row">
                        <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                          <div class="met-profile-main">
                            <div class="met-profile_user-detail">
                              <h5 class="met-user-name">Lot : {titreLot}</h5>
                              <p class="mb-0 met-user-name-post">
                                Entreprise : {entreprise ? entreprise.nom : ''}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 ms-auto align-self-center">
                          <ul class="list-unstyled personal-detail mb-0">
                            <li>
                              <i class="las la-user text-secondary font-22 align-middle "></i>{' '}
                              <b> Responsable </b> :{' '}
                              {entreprise?.responsable?.name
                                ? entreprise.responsable.name
                                : ''}{' '}
                              {entreprise?.responsable?.lastname
                                ? entreprise.responsable.lastname
                                : ''}
                            </li>
                            <li>
                              <i class="las la-phone mr-2 text-secondary font-22 align-middle"></i>{' '}
                              <b> Téléphone </b> : +
                              {entreprise?.responsable?.telephone
                                ? entreprise.responsable.telephone
                                : ''}
                            </li>
                            <li>
                              <i class="las la-envelope text-secondary font-22 align-middle mr-2"></i>{' '}
                              <b> Email </b> :{' '}
                              {entreprise ? entreprise.responsable.email : ''}
                            </li>
                          </ul>
                        </div>
                        <div class="col-lg-4 align-self-center">
                          <div class="row">
                            <Chart
                              options={options}
                              series={series}
                              type="radialBar"
                              width={250}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            data-bs-toggle="tab"
                            href="#Planning"
                            role="tab"
                            aria-selected="true"
                          >
                            Planification
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#Engagement"
                            role="tab"
                            aria-selected="false"
                          >
                            Engagements
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#Administratif"
                            role="tab"
                            aria-selected="false"
                          >
                            Administratifs
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#Documents"
                            role="tab"
                            aria-selected="false"
                          >
                            Documents
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#Securite"
                            role="tab"
                            aria-selected="false"
                          >
                            Sécurités
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#Installation"
                            role="tab"
                            aria-selected="false"
                          >
                            Installation de chantier
                          </a>
                        </li>
                      </ul>

                      <div class="tab-content">
                        <div
                          class="tab-pane p-3 active"
                          id="Planning"
                          role="tabpanel"
                        >
                          <div class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataPlanningSo}
                                columns={colonnePlanning}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: planningEdited }}
                                // options={{
                                //   pagination: 'local',
                                //   paginationSize: 5,
                                // }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addPlanning(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane p-3"
                          id="Engagement"
                          role="tabpanel"
                        >
                          <div class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataEngagement}
                                columns={colonneEngagement}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: engagementEdited }}
                                // options={{
                                //   pagination: 'local',
                                //   paginationSize: 5,
                                // }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addEngagementRow(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane p-3"
                          id="Administratif"
                          role="tabpanel"
                        >
                          <div id="grid" class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataAdministratif}
                                columns={colonneAdministratif}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: administratifEdited }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addAdministratifRow(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane p-3"
                          id="Documents"
                          role="tabpanel"
                        >
                          <div class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataDocument}
                                columns={colonneDocument}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: documentEdited }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addDocumentRow(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane p-3" id="Securite" role="tabpanel">
                          <div id="grid" class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataSecurite}
                                columns={colonneSecurite}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: securiteEdited }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addSecuriteRow(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane p-3"
                          id="Installation"
                          role="tabpanel"
                        >
                          <div id="grid" class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataInstallation}
                                columns={colonneInstallation}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: installationEdited }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addInstallationRow(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Fin profil */}
          </div>
        </div>
      </div>
      {/* Modal Planification */}
      <div class="modal fade" id="myModalPlannification">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Plannification</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                console.log(document.getElementById('modalPlanification').value)
                updateData(
                  {
                    observation:
                      document.getElementById('modalPlanification').value,
                    _id: dataModalPlanification._id,
                  },
                  'planningSo',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataPlanningSo(
                      dataPlanningSo.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              {' '}
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    id="modalPlanification"
                    value={
                      dataModalPlanification.observation
                        ? dataModalPlanification.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalPlanification({
                        ...dataModalPlanification,
                        observation: e.target.value,
                      })
                    }}
                    class="form-control"
                    rows="7"
                    name="text"
                  ></textarea>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Engagement */}
      <div class="modal fade" id="myModalEngagement">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Engagement</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation:
                      document.getElementById('modalEngagement').value,
                    _id: dataModalEngagement._id,
                  },
                  'engagements',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataEngagement(
                      dataEngagement.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    value={
                      dataModalEngagement.observation
                        ? dataModalEngagement.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalEngagement({
                        ...dataModalEngagement,
                        observation: e.target.value,
                      })
                    }}
                    id="modalEngagement"
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Administratif */}
      <div class="modal fade" id="myModalAdministratif">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Administratif</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation:
                      document.getElementById('modalAdministratif').value,
                    _id: dataModalAdministration._id,
                  },
                  'administratifs',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataAdministratif(
                      dataAdministratif.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalAdministratif"
                    value={
                      dataModalAdministration.observation
                        ? dataModalAdministration.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalAdministration({
                        ...dataModalAdministration,
                        observation: e.target.value,
                      })
                    }}
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Securite */}
      <div class="modal fade" id="myModalSecurite">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Sécurité</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation: document.getElementById('modalSecurite').value,
                    _id: dataModalSecurite._id,
                  },
                  'securites',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataSecurite(
                      dataSecurite.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalSecurite"
                    value={
                      dataModalSecurite.observation
                        ? dataModalSecurite.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalSecurite({
                        ...dataModalSecurite,
                        observation: e.target.value,
                      })
                    }}
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Installation */}
      <div class="modal fade" id="myModalInstallation">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Installation chantier</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation:
                      document.getElementById('modalInstallation').value,
                    _id: dataModalInstallation._id,
                  },
                  'installationchantiers',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataInstallation(
                      dataInstallation.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalInstallation"
                    value={
                      dataModalInstallation.observation
                        ? dataModalInstallation.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalInstallation({
                        ...dataModalInstallation,
                        observation: e.target.value,
                      })
                    }}
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal document */}
      <ModalDocument dataModalDocument={dataModalDocument} dataModalSuiviDoc={dataModalSuiviDoc} indiceDoc={indiceDoc} setDataDocument={setDataDocument} setDataModalSuiviDoc={setDataModalSuiviDoc} dataDocument={dataDocument}/>
      <div class="modal fade" id="myModalDocs">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Détails</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div class="modal-body">
              <div className="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th className="text-nowrap text-center">Nom document</th>
                      <th className="text-nowrap text-center">
                        Indice en cours
                      </th>
                      <th className="text-nowrap text-center">
                        Maitrise d'ouvrage
                      </th>
                      <th className="text-nowrap text-center">
                        Bureau de contrôle
                      </th>
                      <th className="text-nowrap text-center">
                        Coordonnateur SSI
                      </th>
                      <th className="text-nowrap text-center">
                        Coordonnateur SPS
                      </th>
                      <th className="text-nowrap text-center">Architecte</th>
                      <th className="text-nowrap text-center">
                        Maitrise d'oeuvre d'exécution
                      </th>
                      <th className="text-nowrap text-center">
                        Cellule synthèse
                      </th>
                      <th className="text-nowrap text-center">
                        Bureau d'études technique
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataModalSuiviDoc &&
                      dataModalSuiviDoc.map((el, i) => {
                        return (
                          <tr>
                            <td className="text-nowrap">
                              {dataModalDocument.titre
                                ? dataModalDocument.titre
                                : 'Non défini'}
                            </td>
                            <td className="text-nowrap">
                              <select
                                className="form-select"
                                disabled={i == 0 ? false : true}
                                onChange={(ev) => {
                                  updateDoc(
                                    el._id,
                                    {
                                      indiceEnCours: ev.target.value,
                                    },
                                    infoUser.token
                                  ).then((res) => {
                                    console.log(res)
                                    if (res.data) {
                                      setDataDocument(
                                        dataDocument.map((doc) => {
                                          if (doc._id == res.data._id) {
                                            return res.data
                                          } else {
                                            return doc
                                          }
                                        })
                                      )
                                    } else {
                                      addToast("Une erreur s'est produit", {
                                        appearance: 'error',
                                        autoDismiss: true,
                                      })
                                    }
                                  })
                                }}
                              >
                                <option
                                  value={
                                    el.indiceEnCours ? el.indiceEnCours : 'A'
                                  }
                                >
                                  {el.indiceEnCours ? el.indiceEnCours : 'A'}
                                </option>
                                {indiceDoc.map((ix) => {
                                  return (
                                    <>
                                      <option
                                        value={ix.value}
                                        disabled={indiceUnique(
                                          dataModalSuiviDoc,
                                          ix.value
                                        )}
                                      >
                                        {ix.value}
                                      </option>
                                    </>
                                  )
                                })}
                              </select>
                            </td>
                            <td className="text-nowrap">
                              {/* Maitrise d'ouvrage */}
                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={
                                      el.maitriseOuvrage?.statut
                                        ? el.maitriseOuvrage.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          maitriseOuvrage: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                {/* <div>
                                  <input
                                    type="date"
                                    className="form-control"
                                    defaultValue={
                                      el.maitriseOuvrage?.date
                                        ? formatDate(el.maitriseOuvrage.date)
                                        : 'jj/mm/aaaa'
                                    }
                                    onChange={(ev)=>{
                                      updateDoc(el._id,{maitriseOuvrage:{
                                        date: ev.target
                                        .value
                                      }},infoUser.token).then((res)=>{
                                        if(res.data){
                                          setDataDocument(dataDocument.map((doc)=>{
                                            if(doc._id==res.data._id){
                                              return res.data
                                            }else{
                                              return doc
                                            }
                                          }))
                                        }
                                      })
                                    }}
                                  />
                                </div> */}

                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          maitriseOuvrage: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value={
                                        el.maitriseOuvrage?.visa?.type
                                          ? el.maitriseOuvrage.visa.type
                                          : 'Non-visé'
                                      }
                                    >
                                      {el.maitriseOuvrage?.visa?.type
                                        ? el.maitriseOuvrage.visa.type
                                        : 'Non-visé'}
                                    </option>
                                    <option value="BPE">BPE</option>
                                    <option value="BPS">BPS</option>
                                    <option value="VSO">VSO</option>
                                    <option value="VAO">VAO</option>
                                    <option value="VAO-B">VAO-B</option>
                                    <option value="REF">REF</option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap ">
                              {/* BUreau de controle */}
                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={
                                      el.bureauDeControle?.statut
                                        ? el.bureauDeControle.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          bureauDeControle: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          bureauDeControle: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value={
                                        el.bureauDeControle?.visa?.type
                                          ? el.bureauDeControle.visa.type
                                          : 'Non-visé'
                                      }
                                    >
                                      {el.bureauDeControle?.visa?.type
                                        ? el.bureauDeControle.visa.type
                                        : 'Non-visé'}
                                    </option>
                                    <option value="BPE">BPE</option>
                                    <option value="BPS">BPS</option>
                                    <option value="VSO">VSO</option>
                                    <option value="VAO">VAO</option>
                                    <option value="VAO-B">VAO-B</option>
                                    <option value="REF">REF</option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* Coordonnateur SSI */}
                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={
                                      el.coordonateurSSI?.statut
                                        ? el.coordonateurSSI.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          coordonateurSSI: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          coordonateurSSI: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value={
                                        el.coordonateurSSI?.visa?.type
                                          ? el.coordonateurSSI.visa.type
                                          : 'Non-visé'
                                      }
                                    >
                                      {el.coordonateurSSI?.visa?.type
                                        ? el.coordonateurSSI.visa.type
                                        : 'Non-visé'}
                                    </option>
                                    <option value="BPE">BPE</option>
                                    <option value="BPS">BPS</option>
                                    <option value="VSO">VSO</option>
                                    <option value="VAO">VAO</option>
                                    <option value="VAO-B">VAO-B</option>
                                    <option value="REF">REF</option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* Coordonnateur SPS */}
                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={
                                      el.coordonateurSPS?.statut
                                        ? el.coordonateurSPS.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          coordonateurSPS: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          coordonateurSPS: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value={
                                        el.coordonateurSPS?.visa?.type
                                          ? el.coordonateurSPS.visa.type
                                          : 'Non-visé'
                                      }
                                    >
                                      {el.coordonateurSPS?.visa?.type
                                        ? el.coordonateurSPS.visa.type
                                        : 'Non-visé'}
                                    </option>
                                    <option value="BPE">BPE</option>
                                    <option value="BPS">BPS</option>
                                    <option value="VSO">VSO</option>
                                    <option value="VAO">VAO</option>
                                    <option value="VAO-B">VAO-B</option>
                                    <option value="REF">REF</option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* Architecte */}

                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={
                                      el.architecte?.statut
                                        ? el.architecte.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          architecte: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          architecte: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value={
                                        el.architecte?.visa?.type
                                          ? el.architecte.visa.type
                                          : 'Non-visé'
                                      }
                                    >
                                      {el.architecte?.visa?.type
                                        ? el.architecte.visa.type
                                        : 'Non-visé'}
                                    </option>
                                    <option value="BPE">BPE</option>
                                    <option value="BPS">BPS</option>
                                    <option value="VSO">VSO</option>
                                    <option value="VAO">VAO</option>
                                    <option value="VAO-B">VAO-B</option>
                                    <option value="REF">REF</option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* Maitrise d'oeuvre d'éxécution */}
                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={
                                      el.maitriseOeuvreExecution?.statut
                                        ? el.maitriseOeuvreExecution.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          maitriseOeuvreExecution: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          maitriseOeuvreExecution: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value={
                                        el.maitriseOeuvreExecution?.visa?.type
                                          ? el.maitriseOeuvreExecution.visa.type
                                          : 'Non-visé'
                                      }
                                    >
                                      {el.maitriseOeuvreExecution?.visa?.type
                                        ? el.maitriseOeuvreExecution.visa.type
                                        : 'Non-visé'}
                                    </option>
                                    <option value="BPE">BPE</option>
                                    <option value="BPS">BPS</option>
                                    <option value="VSO">VSO</option>
                                    <option value="VAO">VAO</option>
                                    <option value="VAO-B">VAO-B</option>
                                    <option value="REF">REF</option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* Cellule synthèse */}
                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={
                                      el.celluleSynthese?.statut
                                        ? el.celluleSynthese.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          celluleSynthese: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          celluleSynthese: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value={
                                        el.celluleSynthese.visa?.type
                                          ? el.celluleSynthese.visa.type
                                          : 'Non-visé'
                                      }
                                    >
                                      {el.celluleSynthese?.visa?.type
                                        ? el.celluleSynthese.visa.type
                                        : 'Non-visé'}
                                    </option>
                                    <option value="BPE">BPE</option>
                                    <option value="BPS">BPS</option>
                                    <option value="VSO">VSO</option>
                                    <option value="VAO">VAO</option>
                                    <option value="VAO-B">VAO-B</option>
                                    <option value="REF">REF</option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* Bureau d'études technique */}
                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={
                                      el.bureauEtudeTechnique?.statut
                                        ? el.bureauEtudeTechnique.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          bureauEtudeTechnique: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          bureauEtudeTechnique: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value={
                                        el.bureauEtudeTechnique.visa?.type
                                          ? el.bureauEtudeTechnique.visa.type
                                          : 'Non-visé'
                                      }
                                    >
                                      {el.bureauEtudeTechnique?.visa?.type
                                        ? el.bureauEtudeTechnique.visa.type
                                        : 'Non-visé'}
                                    </option>
                                    <option value="BPE">BPE</option>
                                    <option value="BPS">BPS</option>
                                    <option value="VSO">VSO</option>
                                    <option value="VAO">VAO</option>
                                    <option value="VAO-B">VAO-B</option>
                                    <option value="REF">REF</option>
                                  </select>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
              <button
                type="button"
                class="btn btn-sm btn-primary mt-2"
                /* data-bs-dismiss="modal" */
                onClick={(e) => {
                  addDoc(
                    dataModalDocument._id,
                    infoChantier.planning,
                    infoUser.token
                  ).then((res) => {
                    if (res.data) {
                      setDataModalSuiviDoc([res.data, ...dataModalSuiviDoc])
                    } else {
                      addToast("Une erreur s'est produit", {
                        appearance: 'error',
                        autoDismiss: true,
                      })
                    }
                  })
                }}
              >
                Ajouter +
              </button>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success"
                data-bs-dismiss="modal"
              >
                Enregistrer
              </button>
              <button
                type="button"
                class="btn btn-dark"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>

      {wait && (
        <>
          <div style={{ position: 'fixed', top: '40%', left: '55%' }}>
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
      )}
    </>
  )
}

export default ProfilEntreprise
