import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Upload } from 'antd'
import { upload } from '../../../../helpers/planning/dashBoard/upload'
import { gantt } from 'dhtmlx-gantt'
import 'antd/dist/reset.css'
import Spinner from './Spinner'
import PageNotFound from '../../../../components/404/PageNotFound'
//page pour l'upload d'un nouveau planning

const UploadFile = ({bool,setBool}) => {
  const navigate = useNavigate()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const [file, setFile] = useState({})
  const [existFile, setExistFile] = useState(false)
  const [waits, setWaits] = useState(false)
  const [spin, setSpin] = useState(true)
  const [error, setError] = useState(false)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  useEffect(() => {}, [file, spin, existFile, waits])

  return (
    <>
      <Upload.Dragger
        multiple={false}
        maxCount={1}
        beforeUpload={(files) => {
          setFile(files)
          setExistFile(true)
          return false
        }}
        showUploadList={{ showRemoveIcon: false }}
        accept=".xml,.mpp"
        listType="picture"
      >
        Glisser le fichier .mpp ou <br />
        <Button>Parcourir Fichier</Button>
      </Upload.Dragger>
      {/* <form
        action="https://export.dhtmlx.com/gantt"
        method="POST"
        enctype="multipart/form-data"
      >
        <input type="file" name="file" />
        <input type="hidden" name="type" value="msproject-parse" />
        <button type="submit">Get</button>
      </form> */}
      {existFile && (
        <div
          className="container"
          style={{
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2%',
          }}
        >
          <button
            className="btn btn-success"
            onClick={(e) => {
              setError(false)
              setWaits(true)
              setExistFile(false)
              upload(file, infoChantier._id, infoUser.token).then((e) => {
                console.log(e)
                localStorage.setItem('infoChantiers', JSON.stringify(e))
                if (e?.status == 'success') {
                  setSpin(false)
                  setExistFile(false)
                  setWaits(false)
                  setError(false)
                  navigate('/Planning_Dashboard')
                  setBool(!bool)
                } else {
                  setSpin(false)
                  navigate('/Acceuil')
                }
                if (e?.message == 'Network Error') {
                  setWaits(false)
                  setError(true)
                  navigate('/Acceuil')
                  dispatch({
                    type: 'bool/modifier',
                    payload: 'rien',
                  })
                }
              })
            }}
          >
            charger
          </button>
        </div>
      )}
      {error && (
        <h1 className="text-center text-danger" style={{ marginTop: '3vh' }}>
          Erreur l'hors du convertion des données, veuillez ressayer dans un
          instant{' '}
        </h1>
      )}
      {waits && (
        <Spinner importation={false} convertion={spin} exploit={spin} />
      )}
    </>
  )
}

export default UploadFile
