import React from 'react'
import { useNavigate } from 'react-router-dom'
import imgUser from '../../assets/images/sooda_logo/default.png'
import PageNotFound from '../404/PageNotFound'
const Header = ({bool}) => {
  const navigate = useNavigate()
  const info = localStorage.getItem('users')
  const infoUser = JSON.parse(info)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  return (
    <>
      <div className="topbar">
        <nav className="navbar-custom" id="navbar-custom">
          <ul className="list-unstyled topbar-nav float-end mb-0">
            <li className="dropdown">
              <a
                className="nav-link dropdown-toggle nav-user"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false" 
              >
                <div class="d-flex align-items-center m-3">
                  <img
                    src={imgUser}
                    alt="profile-user"
                    class="rounded-circle me-2 thumb-sm"
                  />
                  <div>
                    <small className="d-none d-md-block font-11">
                      {infoUser.user.name}
                    </small>
                    <span className="d-none d-md-block fw-semibold font-12">
                      ADMINISTRATEUR{' '}
                      {/* <i className="mdi mdi-chevron-down"></i> */}
                    </span>
                  </div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    navigate('/profil')
                  }}
                >
                  <i class="ti ti-user font-16 me-1 align-text-bottom"></i>{' '}
                  Profil
                </a> */}
                 {/* <a className="dropdown-item" href="#">
                  <i className="ti ti-settings font-16 me-1 align-text-bottom"></i>{" "}
                  Paramettre
                </a>  */}
                <div className="dropdown-divider mb-0"></div>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    sessionStorage.clear()
                    localStorage.removeItem('isLogin')
                    localStorage.removeItem('allGroupe')
                    localStorage.removeItem('horsLigne')
                    localStorage.removeItem('_id')
                    localStorage.removeItem('titreLot')
                    localStorage.removeItem('infoChantiers')
                    navigate('/')
                  }}
                >
                  <i className="ti ti-power font-16 me-1 align-text-bottom"></i>{' '}
                  Déconnexion
                </a>
              </div>
            </li>
          </ul>

          <ul className="list-unstyled topbar-nav mb-0">
            <li>
              <button
                className="nav-link button-menu-mobile nav-icon"
                id="togglemenu"
                onClick={(e) => {
                  document
                    .getElementById('body')
                    .classList.toggle('enlarge-menu')
                }}
              >
                <i class="ti ti-menu-2"></i>
              </button>
            </li>
            <li class="hide-phone app-search" style={{marginLeft:'20px',marginTop:"10px"}}>
              <h4 className='page-title'>
              { 
               bool ? (infoChantier?.titre ? (infoChantier.titre).toUpperCase() : '') : ""
              }
              </h4>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Header
