import axios from "axios"
export const getAllPlan = async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/ressources/allplans/?planning=${planning}`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getTacheAssigner = async (planning,dateD1,dateD2,date1,date2,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/taches/filter?planning=${planning}&limit=1000&${dateD1}=${date1}&${dateD2}=${date2}&fille=true`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}

export const deleteMedia = async (id,token) => {
    console.log(id);
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.delete(`${process.env.REACT_APP_URL}/api/v1/ressources/deletephoto/${id}`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}