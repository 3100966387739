import axios from 'axios'
import React, { useState,useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
const AddEnt = ({ bool, setBool,listUser }) => {
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const { addToast } = useToasts()
  const [nom, setNom] = useState()
  const [siret, setSiret] = useState('')
  const [adresse,setAdresse] = useState('')
  const [ville,setVille] = useState('')
  const [pays,setPays] =  useState('')
  const [responsable, setResponsable] = useState(listUser ? listUser[0] : "")
  const [photo,setPhoto] =useState()
  const saveEnt = () => {
    if(nom){
      const formData = new FormData()
      formData.append('nom',nom)
      formData.append('adrese',adresse)
      formData.append('zipCode',siret)
      formData.append('ville',ville)
      formData.append('pays',pays)
      formData.append('responsable',responsable)
      formData.append('photo',photo)
      console.log(responsable);
       axios.post(`${process.env.REACT_APP_URL}/api/v1/entreprises`,formData,{
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${infoUser.token}`,
        },
      }).then((res)=>{
        console.log(res);
        addToast("L'entreprise a été enregistrée avec succès .", {
          appearance: 'success',
          autoDismiss: true,
        })
        setBool(!bool)
      }).catch((err)=>{
        console.log(err);
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
      }) 
    }else{
      addToast("Une erreur s'est produit.", {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }
  useEffect(()=>{
   setResponsable(listUser ? listUser[0] : '')
  },[])
  return (
    <div class="modal" id="myModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Nouvelle entreprise</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <form onSubmit={(e)=>{
            e.preventDefault()
            e.target.reset()
            saveEnt()
          }}>
            <div class="modal-body">
              <div class="form-floating mb-3 mt-3">
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => {
                    setNom(e.target.value)
                  }}
                />
                <label>Nom</label>
              </div>{' '}
              <div class="form-floating mb-3 mt-3">
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => {
                    setSiret(e.target.value)
                  }}
                />
                <label>SIRET</label>
              </div>{' '}
              <div class="form-floating mb-3 mt-3">
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => {
                    setAdresse(e.target.value)
                  }}
                />
                <label>Addresse</label>
              </div>{' '}
              <div class="form-floating mb-3 mt-3">
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => {
                    setVille(e.target.value)
                  }}
                />
                <label>Ville</label>
              </div>{' '}
              <div class="form-floating mb-3 mt-3">
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => {
                    setPays(e.target.value)
                  }}
                />
                <label>Pays</label>
              </div>{' '}
              <div class="form-floating mb-3 mt-3">
                <input
                  type="file"
                  class="form-control"
                  accept='image/*'
                  onChange={(e) => {
                    setPhoto(e.target.files[0])
                  }}
                />
                <label>Logo entreprise</label>
              </div>{' '}
              <div class=" mb-3 mt-3">
                <label> Responsable</label>
                <select class="form-select" onChange={(e)=>{
                  setResponsable(e.target.value)
                }}>
                  <option value="">Sélectionner une entreprise</option>
                  {
                    listUser && listUser.map((user)=>{
                        return (

                            <>
                            <option value={user._id}>{`${user.name ? user.name : ""} ${user.lastname ? user.lastname : ""} (${user.email ? user.email : ""})`}</option>
                            </>
                        )
                    })
                  }
                </select>
              </div>{' '}
            </div>

            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-success"
                data-bs-dismiss="modal"
              >
                Enregistrer
              </button>
              <button
                type="button"
                class="btn btn-dark"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddEnt
