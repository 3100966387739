import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import { ReactTabulator } from 'react-tabulator'
import {
  getAllCR,
  getAllFiche,
  updateCr,
  updatefiche,
  getAllObservation,
  updateObservation,
} from '../../../helpers/rapport/rapport'
import PageNotFound from '../../../components/404/PageNotFound'
import BeatLoader from 'react-spinners/BeatLoader'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'
import { searchOptimizedData } from '../../../helpers/recherche/recheche'
//import {crypto} from "crypto"
//page des comptes rendu et fiche de visite
const Rapport = () => {
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const [bool, setBool] = useState(false)
  const [cr, setCr] = useState([])
  const [fiche, setFiche] = useState([])
  const [allObservation, setAllObservation] = useState([])
  const [wait, setWait] = useState(false)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  useEffect(() => {
    setWait(true)
    getAllObservation(infoChantier.planning, infoUser.token)
      .then((res) => {
        if (res.data) {
          console.log(res.data)
          setAllObservation(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    getAllCR(infoChantier.planning, infoUser.token).then((e) => {
      console.log(e)
      if (e.name == 'AxiosError') {
        setCr([])
      } else {
        setCr(e.data)
      }
    })
    getAllFiche(infoChantier.planning, infoUser.token).then((e) => {
      console.log(e)
      if (e.name == 'AxiosError') {
        setFiche([])
      } else {
        console.log(e.data)
        setFiche(e.data)
      }
      setWait(false)
    })
  }, [bool])
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.disabled = true
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.addEventListener('blur', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }

  const checkboxCR = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = data.archive
    input.addEventListener('change', function (e) {
      e.preventDefault()
      console.log(e.target.checked)
      updateCr(data._id, e.target.checked, infoUser.token)
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }
  const checkboxFiche = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = data.archive
    input.addEventListener('change', function (e) {
      e.preventDefault()
      console.log(e.target.checked)
      updatefiche(data._id, e.target.checked, infoUser.token)
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }

  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked =
      cell.getField() == 'alreadySent' ? !cell.getValue() : cell.getValue()
    input.addEventListener('change', function (e) {
      e.preventDefault()
      if (cell.getField() == 'alreadySent') {
        cell.setValue(!e.target.checked)
      } else {
        cell.setValue(e.target.checked)
      }
      updateObservation(cell.getData(), infoUser.token, infoChantier.planning)
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = cell.getValue()
    input.disabled = true
    /*  input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    }) */
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }

  const colonneCr = [
    { title: 'ID', field: 'idCR', formatter: simpleInput },
    { title: 'Date', field: 'dateCR', formatter: date },
    { title: 'Nom', field: 'nomFichier', formatter: simpleInput },
    { title: 'Emmetteur', field: 'emetteur', formatter: simpleInput },
    { title: 'Archive', width: 145, field: 'archive', formatter: checkboxCR },
    /* {
      title: 'DUREE',
      field: 'duration',
    }, */
  ]
  const colonneFiche = [
    { title: 'ID', field: 'idVisite', formatter: simpleInput },
    { title: 'Date', field: 'dateExport', formatter: date },
    { title: 'Nom', field: 'nomDocument', formatter: simpleInput },
    { title: 'Emmetteur', field: 'emetteur', formatter: simpleInput },
    {
      title: 'Archive',
      width: 145,
      field: 'archive',
      formatter: checkboxFiche,
    },
    /* {
      title: 'DUREE',
      field: 'duration',
    }, */
  ]

  const colonneObservation = [
    { title: 'ID', field: '_id', formatter: simpleInput },
    { title: 'Observation', field: 'observation', formatter: simpleInput },
    /* { title: 'Description', field: 'description',formatter:simpleInput }, */
    { title: 'Plan', width: 145, field: 'plan', formatter: simpleInput },
    { title: 'Active', width: 145, field: 'alreadySent', formatter: checkbox },
    { title: 'Archive', width: 145, field: 'archive', formatter: checkbox },
    /* {
      title: 'DUREE',
      field: 'duration',
    }, */
  ]

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fuild">
            <div class="page-title-box">
              <div class="float-end"></div>
              <h4 class="page-title">
                Gestion des comptes rendus et fiches de visites
              </h4>
            </div>
            {/* debut rapport  */}
            <div class="row">
              <div class="col-lg-3">
                <div class="card">
                  <div class="card-header">
                    <div class="row align-items-center">
                      <div class="col">
                        <h4 class="card-title">Categories</h4>
                      </div>
                      <div class="col-auto"></div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="files-nav">
                      <div
                        class="nav flex-column nav-pills"
                        id="files-tab"
                        aria-orientation="vertical">
                        <a
                          class="nav-link active"
                          id="files-projects-tab"
                          data-bs-toggle="pill"
                          href="#files-projects"
                          aria-selected="true">
                          <i
                            data-feather="folder"
                            class="align-self-center icon-dual-file icon-sm me-2"></i>
                          <div class="d-inline-block align-self-center">
                            <h5 class="m-0">Comptes Rendus</h5>
                          </div>
                          <span class="badge bg-success ms-auto font-10">
                            {cr?.length ? cr.length : '0'}
                          </span>
                        </a>

                        <a
                          class="nav-link  align-items-center"
                          id="files-documents-tab"
                          data-bs-toggle="pill"
                          href="#files-documents"
                          aria-selected="false">
                          <i
                            data-feather="folder"
                            class="align-self-center icon-dual-file icon-sm me-2"></i>
                          <div class="d-inline-block align-self-center">
                            <h5 class="m-0">Fiches de visites</h5>
                          </div>
                          <span class="badge bg-success ms-auto font-10">
                            {fiche.length ? fiche.length : 0}
                          </span>
                        </a>
                        <a
                          class="nav-link  align-items-center"
                          id="files-documents-tab"
                          data-bs-toggle="pill"
                          href="#files-observation"
                          aria-selected="false">
                          <i
                            data-feather="folder"
                            class="align-self-center icon-dual-file icon-sm me-2"></i>
                          <div class="d-inline-block align-self-center">
                            <h5 class="m-0">Observations</h5>
                          </div>
                          <span class="badge bg-success ms-auto font-10">
                            {allObservation.length ? allObservation.length : 0}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-9">
                <div class="">
                  <div class="tab-content" id="files-tabContent">
                    <div class="float-end">
                      {/* <button
                        class="dropdown-toggle btn btn-primary"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Nouveau
                        <i className="ti ti-selector"></i>
                      </button> */}
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1">
                        <li
                          onClick={(e) => {
                            e.preventDefault()
                            navigate('/Rapport_NewCR')
                          }}>
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{ fontSize: '1vw' }}>
                            Comptes rendus
                          </a>
                        </li>
                        <li
                          onClick={(e) => {
                            e.preventDefault()
                            navigate('/Rapport_NewFiche')
                          }}>
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{ fontSize: '1vw' }}>
                            Fiches de visites
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="tab-pane fade show active" id="files-projects">
                      <div
                        class="file-box-content"
                        style={{
                          backgroundColor: 'white',
                          borderRadius: '12px',
                        }}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '30px 20px',
                          }}>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                              style={{
                                color: '#334155',
                                fontSize: '20px',
                                fontWeight: '600',
                              }}>
                              Listes des Comptes Rendus
                            </span>
                            <span
                              style={{
                                backgroundColor: '#E0E7FF',
                                borderRadius: '24px',
                                padding: '6px 12px',
                                color: '#64748B',
                                fontSize: '14px',
                                fontWeight: '600',
                                marginLeft: '8px',
                                maxHeight: '32px',
                                width: 'auto',
                              }}>
                              {cr.length > 0 ? cr.length : '0'} Compte rendus
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                padding: '8px 12px 8px 12px',
                                backgroundColor: 'rgba(226, 232, 240, 0.6)',
                                borderRadius: '8px',
                                height: '40px',
                              }}>
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="#818CF8"
                                  style={{ width: '18px', height: '18px' }}>
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                  />
                                </svg>
                              </span>
                              <input
                                type="text"
                                placeholder="Recherche"
                                style={{
                                  border: 'none',
                                  backgroundColor: 'transparent',
                                }}
                                onChange={(ev) => {
                                  ev.preventDefault()
                                  ev.stopPropagation()
                                  const newData = searchOptimizedData(
                                    ev.target.value,
                                    cr
                                  )
                                  setCr(newData)
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <ReactTabulator
                          data={cr ? cr : []}
                          columns={colonneCr}
                          layout={'fitColumns'}
                          options={{
                            locale: true,
                            pagination: 'local',
                            paginationSize: 10,
                            langs: {
                              fr: {
                                pagination: {
                                  first: 'Premier',
                                  first_title: 'Première page',
                                  last: 'Dernier',
                                  last_title: 'Dernière page',
                                  prev: 'Précédent',
                                  prev_title: 'Page précédente',
                                  next: 'Suivant',
                                  next_title: 'Page suivante',
                                  page_size: 'Taille de page',
                                  page_title: 'Afficher la page',
                                  all: 'Tous',
                                },
                              },
                            },
                          }}
                        />
                      </div>
                    </div>

                    <div class="tab-pane fade" id="files-documents">
                      <div
                        class="file-box-content"
                        style={{
                          backgroundColor: 'white',
                          borderRadius: '12px',
                        }}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '30px 20px',
                          }}>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                              style={{
                                color: '#334155',
                                fontSize: '20px',
                                fontWeight: '600',
                              }}>
                              Listes des Rapports des visites
                            </span>
                            <span
                              style={{
                                backgroundColor: '#E0E7FF',
                                borderRadius: '24px',
                                padding: '6px 12px',
                                color: '#64748B',
                                fontSize: '14px',
                                fontWeight: '600',
                                marginLeft: '8px',
                                maxHeight: '32px',
                                width: 'auto',
                              }}>
                              {fiche.length > 0 ? fiche.length : '0'} rapports
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                padding: '8px 12px 8px 12px',
                                backgroundColor: 'rgba(226, 232, 240, 0.6)',
                                borderRadius: '8px',
                                height: '40px',
                              }}>
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="#818CF8"
                                  style={{ width: '18px', height: '18px' }}>
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                  />
                                </svg>
                              </span>
                              <input
                                type="text"
                                placeholder="Recherche"
                                style={{
                                  border: 'none',
                                  backgroundColor: 'transparent',
                                }}
                                onChange={(ev) => {
                                  ev.preventDefault()
                                  ev.stopPropagation()
                                  const newData = searchOptimizedData(
                                    ev.target.value,
                                    fiche
                                  )
                                  setFiche(newData)
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <ReactTabulator
                          data={fiche ? fiche : []}
                          columns={colonneFiche}
                          layout={'fitColumns'}
                          options={{
                            locale: true,
                            pagination: 'local',
                            paginationSize: 10,
                            langs: {
                              fr: {
                                pagination: {
                                  first: 'Premier',
                                  first_title: 'Première page',
                                  last: 'Dernier',
                                  last_title: 'Dernière page',
                                  prev: 'Précédent',
                                  prev_title: 'Page précédente',
                                  next: 'Suivant',
                                  next_title: 'Page suivante',
                                  page_size: 'Taille de page',
                                  page_title: 'Afficher la page',
                                  all: 'Tous',
                                },
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div class="tab-pane fade" id="files-observation">
                      <div class="file-box-content" style={{ backgroundColor: 'white',borderRadius:'12px' }}>
                      <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '30px 20px',
                          }}>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                              style={{
                                color: '#334155',
                                fontSize: '20px',
                                fontWeight: '600',
                              }}>
                              Listes des Observations
                            </span>
                            <span
                              style={{
                                backgroundColor: '#E0E7FF',
                                borderRadius: '24px',
                                padding: '6px 12px',
                                color: '#64748B',
                                fontSize: '14px',
                                fontWeight: '600',
                                marginLeft: '8px',
                                maxHeight: '32px',
                                width: 'auto',
                              }}>
                              {allObservation.length > 0 ? allObservation.length : '0'} Observations
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                padding: '8px 12px 8px 12px',
                                backgroundColor: 'rgba(226, 232, 240, 0.6)',
                                borderRadius: '8px',
                                height: '40px',
                              }}>
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="#818CF8"
                                  style={{ width: '18px', height: '18px' }}>
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                  />
                                </svg>
                              </span>
                              <input
                                type="text"
                                placeholder="Recherche"
                                style={{
                                  border: 'none',
                                  backgroundColor: 'transparent',
                                }}
                                onChange={(ev) => {
                                  ev.preventDefault()
                                  ev.stopPropagation()
                                  const newData = searchOptimizedData(
                                    ev.target.value,
                                    allObservation
                                  )
                                  setAllObservation(newData)
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <ReactTabulator
                          data={allObservation ? allObservation : []}
                          columns={colonneObservation}
                          layout={'fitColumns'}
                          options={{
                            locale: true,
                            pagination: 'local',
                            paginationSize: 10,
                            langs: {
                              fr: {
                                pagination: {
                                  first: 'Premier',
                                  first_title: 'Première page',
                                  last: 'Dernier',
                                  last_title: 'Dernière page',
                                  prev: 'Précédent',
                                  prev_title: 'Page précédente',
                                  next: 'Suivant',
                                  next_title: 'Page suivante',
                                  page_size: 'Taille de page',
                                  page_title: 'Afficher la page',
                                  all: 'Tous',
                                },
                              },
                            },
                          }}
                        />
                      </div>
                    </div>

                    <div class="tab-pane fade" id="files-hide">
                      <h4 class="mt-0 card-title mb-3">Hide</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* fin rapport */}
          </div>
        </div>
      </div>
      {wait && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '40%',
              left: '55%',
              zIndex: '10000',
            }}>
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
      )}
    </>
  )
}

export default Rapport
