import React from 'react'
import axios from 'axios'
const ModalDetail = ({ e, updateBool, bool }) => {
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const config = {
    headers: {
      Authorization: `Bearer ${infoUser.token}`,
    },
  }
  return (
    <>
      <div class="modal" id={`myModalDetails${e._id}`}>
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Modification de base</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col">
                  <div class="form-floating mb-3 mt-3">
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      value={e._id ? e._id : ''}
                      name="email"
                      disabled
                    />
                    <label for="email">_id</label>
                  </div>
                </div>
                <div className="col">
                  <div class="form-floating mb-3 mt-3">
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      defaultValue={e.titre ? e.titre : ''}
                      onBlur={(ev) => {
                        axios
                          .patch(
                            `${process.env.REACT_APP_URL}/api/v1/chantiers/${e._id}`,
                            { titre: ev.target.value },
                            config
                          )
                          .then((res) => {
                            updateBool(!bool)
                          })
                      }}
                    />
                    <label for="email">Nom du chantier</label>
                  </div>
                </div>
                <div className="col">
                  <div class="form-floating mt-3 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="pwd"
                      defaultValue={e.adresse ? e.adresse : ''}
                      onBlur={(ev) => {
                        axios
                          .patch(
                            `${process.env.REACT_APP_URL}/api/v1/chantiers/${e._id}`,
                            { adresse: ev.target.value },
                            config
                          )
                          .then((res) => {
                            updateBool(!bool)
                          })
                      }}
                    />
                    <label for="pwd">Addresse du chantier</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div class="form-floating mb-3 mt-3">
                    <input
                      type="file"
                      class="form-control"
                      accept="image/*"
                      onChange={(ev) => {
                        const formData = new FormData()
                        console.log(ev.target.files[0])
                        formData.append('photo', ev.target.files[0])
                        axios
                          .patch(
                            `${process.env.REACT_APP_URL}/api/v1/chantiers/${e._id}`,
                            formData,
                            config
                          )
                          .then((res) => {
                            console.log(res)
                            updateBool(!bool)
                          })
                      }}
                    />
                    <label for="email">photo</label>
                  </div>
                </div>
                <div className="col">
                  <div class="form-floating mb-3 mt-3">
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      defaultValue={e.nbJours ? e.nbJours : ''}
                      name="email"
                      disabled
                    />
                    <label for="email">Durée</label>
                  </div>
                </div>
                <div className="col">
                  <div class="form-floating mt-3 mb-3">
                    <select
                      className="form-select"
                      onChange={(ev) => {
                        axios
                          .patch(
                            `${process.env.REACT_APP_URL}/api/v1/chantiers/${e._id}`,
                            { statut: ev.target.value },
                            config
                          )
                          .then((res) => {
                            console.log(res)
                            updateBool(!bool)
                          })
                      }}>
                      <option
                        value="Inactif"
                        selected={!e.statut ? true : false}
                        style={{ display: `${e.statut ? 'none' : 'block'}` }}>
                        Choisir un statut
                      </option>
                      <option
                        value="Actif"
                        selected={e.statut == 'Actif' ? true : false}>
                        Actif
                      </option>
                      <option
                        value="Inactif"
                        selected={e.statut == 'Inactif' ? true : false}>
                        Inactif
                      </option>
                      <option
                        value="Terminé"
                        selected={e.statut == 'Terminé' ? true : false}>
                        Terminé
                      </option>
                    </select>
                    {/* <input
                      type="text"
                      class="form-control"
                      id="pwd"
                      defaultValue={e.statut ? e.statut : ''}
                    /> */}
                    <label for="pwd">Statut</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div class="form-floating mb-3 mt-3">
                    <input
                      type="text"
                      class="form-control"
                      defaultValue={e.client ? e.client : ''}
                      onBlur={(ev) => {
                        axios
                          .patch(
                            `${process.env.REACT_APP_URL}/api/v1/chantiers/${e._id}`,
                            { client: ev.target.value },
                            config
                          )
                          .then((res) => {
                            updateBool(!bool)
                          })
                      }}
                    />
                    <label for="email">Client</label>
                  </div>
                </div>
                <div className="col">
                  <div class="form-floating mb-3 mt-3">
                    <input
                      type="file"
                      class="form-control"
                      accept="image/*"
                      onChange={(ev) => {
                        const formData = new FormData()
                        console.log(ev.target.files[0])
                        formData.append('logoClient', ev.target.files[0])
                        axios
                          .patch(
                            `${process.env.REACT_APP_URL}/api/v1/chantiers/${e._id}`,
                            formData,
                            config
                          )
                          .then((res) => {
                            console.log(res)
                            updateBool(!bool)
                          })
                      }}
                    />
                    <label for="email">Logo du client</label>
                  </div>
                </div>
                <div className="col">
                  <div class="form-floating mt-3 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="pwd"
                      defaultValue={e.etoile ? e.etoile : ''}
                      disabled
                    />
                    <label for="pwd">Etoile</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div class="form-floating mt-3 mb-3">
                    <select
                      className="form-select"
                      onChange={(ev) => {
                        axios
                          .patch(
                            `${process.env.REACT_APP_URL}/api/v1/chantiers/${e._id}`,
                            { liaisonTaches: ev.target.value },
                            config
                          )
                          .then((res) => {
                            console.log(res)
                            updateBool(!bool)
                          })
                      }}>
                      <option
                        value={true}
                        selected={e.liaisonTaches ? true : false}>
                        Afficher
                      </option>
                      <option
                        value={false}
                        selected={!e.liaisonTaches ? true : false}>
                        Masquer 
                      </option>
                    </select>
                    {/* <input
                      type="text"
                      class="form-control"
                      id="pwd"
                      defaultValue={e.statut ? e.statut : ''}
                    /> */}
                    <label for="pwd">Liaison des taches </label>
                  </div>
                </div>
                <div className="col"></div>
                <div className="col"></div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-dark"
                data-bs-dismiss="modal">
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalDetail
