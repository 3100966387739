import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import LastPlanner2 from './LastPlanner2'
import { useSelector} from 'react-redux'
import PageNotFound from '../../../components/404/PageNotFound'
const LastPlanner = () => {
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
    infoUser?.user?.role?.roleType != 'opc')
  ) {
    return <PageNotFound />
  }

  const [havePlanning, setHavePlaning] = useState(false)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const navigate = useNavigate()
  const bool = useSelector((state) => state.bool.bool)
  useEffect(() => {
    console.log(bool)
    console.log('modification')
    if (infoChantier.planning) {
      setHavePlaning(true)
    } else {
      setHavePlaning(false)
    }
  }, [bool])

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <h4 class="page-title">Planification</h4>
                </div>
              </div>
            </div>
            {/* debut NavBar */}
            <ul class="nav nav-pills">
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Dashboard')}
              >
                <a class="nav-link" href="#">
                  Tableau de bord
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_GestionTache')}
              >
                <a class="nav-link active" href="#">
                  Gestion des tâches
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Pointage')}
              >
                <a class="nav-link" href="#">
                  Pointage
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Favori')}
              >
                <a class="nav-link" href="#">
                  Pour mémoire  
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Retard')}
              >
                <a class="nav-link" href="#">
                  Retards
                </a>
              </li>
            </ul>
            {/* Fin NavBar */}

            {/** Debut last planner */}
            <div
              className="container-fluid"
              style={{ width: '100%', marginTop: '1vw', position: 'relative' }}
            >
              {havePlanning ? (
                <>
                  <LastPlanner2 zoom={true} planning={infoChantier.planning}/>
                </>
              ) : (
                <>
                  <h1>vous devez importer un planning</h1>
                </>
              )}
            </div>
            {/** Fin last planner */}
          </div>
        </div>
      </div>
    </>
  )
}

export default LastPlanner
