import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import {
  getAllPlan,
  getTacheAssigner,
} from '../../../helpers/observation/observation'
import { getAllData } from '../../../helpers/suivi/suivi'
import ImageMarker from 'react-image-marker'
import Pin from './component/Pin'
import Modal from './component/Modal'
import { useSelector } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import PageNotFound from '../../../components/404/PageNotFound'
//import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
//page de gestion des observations
const Observation = () => {
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
    infoUser?.user?.role?.roleType != 'opc')
  ) {
    return <PageNotFound />
  }
  const bool = useSelector((state) => state.bool.bool)
  const navigate = useNavigate()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  console.log(infoUser)
  const [data, setData] = useState([])
  const [trueData, setTrueData] = useState([])
  const [markers, setMarkers] = useState([])
  const [image, setImage] = useState('')
  const [id, setId] = useState()
  const [show, setShow] = useState(false)
  const [filter, setFilter] = useState('')
  const [allLot, setAllLot] = useState([])
  const [nomMedia, setNomMedia] = useState('')
  const [wait, setWait] = useState(true)
  const [tacheSd, setTacheSd] = useState([])
  const [tacheSc, setTacheSc] = useState([])
  const [tacheSp, setTacheSp] = useState([])
  const getDate = (date) => {
    const oneDay = 24 * 60 * 60 * 1000
    const dateDuCalcul = date || new Date()
    const currentDayOfWeek = dateDuCalcul.getDay()
    const monday = new Date(date.getTime() - (currentDayOfWeek - 1) * oneDay)

    const friday = new Date(monday.getTime() + 4 * oneDay)

    const previousMonday = new Date(monday.getTime() - 7 * oneDay)

    const previousFriday = new Date(friday.getTime() - 7 * oneDay)

    const nextMonday = new Date(monday.getTime() + 7 * oneDay)

    const nextFriday = new Date(friday.getTime() + 7 * oneDay)

    const dates = {
      currentWeek: {
        start: monday.toISOString().slice(0, 10),
        end: friday.toISOString().slice(0, 10),
      },
      previousWeek: {
        start: previousMonday.toISOString().slice(0, 10),
        end: previousFriday.toISOString().slice(0, 10),
      },
      nextWeek: {
        start: nextMonday.toISOString().slice(0, 10),
        end: nextFriday.toISOString().slice(0, 10),
      },
    }

    return dates
  }
  useEffect(() => {
    const date = getDate(new Date(Date.now()))
    console.log(date)
    //S-1
    getTacheAssigner(
      infoChantier.planning,
      'end_date[lte]',
      'end_date[gte]',
      date.previousWeek.end,
      date.previousWeek.start,
      infoUser.token
    ).then((e) => {
      console.log(e)
      if (e.data) {
        setTacheSd(e.data)
      }
    })
    //S
    getTacheAssigner(
      infoChantier.planning,
      'start_date[lte]',
      'end_date[gte]',
      date.currentWeek.start,
      date.currentWeek.end,
      infoUser.token
    ).then((e1) => {
      console.log(e1)
      getTacheAssigner(
        infoChantier.planning,
        'start_date[gte]',
        'start_date[lte]',
        date.currentWeek.start,
        date.currentWeek.end,
        infoUser.token
      ).then((e2) => {
        console.log(e2)
        getTacheAssigner(
          infoChantier.planning,
          'end_date[gte]',
          'end_date[lte]',
          date.currentWeek.start,
          date.currentWeek.end,
          infoUser.token
        ).then((e3) => {
          console.log(e3)
          if (e1.data && e2.data && e3.data) {
            const tableauFusionner = [...e1.data, ...e2.data, ...e3.data]
            const tableauSansDouble = tableauFusionner.filter((obj, i, tab) => {
              const position = tab.findIndex((item) => item.id === obj.id)
              return position == i
            })
            setTacheSc(tableauSansDouble)
          }
        })
      })
    })
    //S+1
    getTacheAssigner(
      infoChantier.planning,
      'start_date[gte]',
      'start_date[lte]',
      date.nextWeek.start,
      date.nextWeek.end,
      infoUser.token
    ).then((e) => {
      console.log(e)
      if (e.data) {
        setTacheSp(e.data)
      }
    })
    if (document.querySelector('.image-marker__marker')) {
      document
        .querySelector('.image-marker__marker')
        .setAttribute('draggable', 'true')
    }
  }, [])
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots/simple',
      infoUser.token
    ).then((e) => {
      if (e.data) {
        setAllLot(e.data)
        localStorage.setItem('allLot', JSON.stringify(e.data))
      } else {
        localStorage.setItem('allLot', JSON.stringify([]))
      }
    })
  }, [])
  useEffect(() => {
    setWait(true)
    getAllPlan(infoChantier.planning, infoUser.token).then((e) => {
      console.log(e)
      if (e.name == 'AxiosError') {
        setData([])
      } else {
        setData(e.data)
        setTrueData(e.data)
        localStorage.setItem('listPlan', JSON.stringify(e.data))
        setWait(false)
        e.data.map((media) => {
          if (media.nomMedia == nomMedia) {
            setImage(media.nomMedia)
            setMarkers(media.pins)
            setId(media._id)
            setShow(true)
            setWait(false)
          }
        })
      }
    })
  }, [bool, nomMedia])

  useEffect(() => {}, [])

  useEffect(() => {}, [image, data, filter])

  useEffect(() => {
    if (filter.length > 0) {
      setData(trueData.filter((e) => e.description.includes(filter)))
    } else {
      setData(trueData)
    }
  }, [filter])

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <h4 class="page-title">Observations</h4>
                </div>
                <div
                  className="d-flex"
                  style={{ justifyContent: 'space-between' }}
                >
                  <div>
                    <button
                      class="dropdown-toggle btn btn-success"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Mode
                      <i className="ti ti-selector"></i>
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/Observation_OffLine')
                        }}
                      >
                        <a
                          class="dropdown-item"
                          style={{ fontSize: '1.2vw', cursor: 'pointer' }}
                        >
                          Hors ligne
                        </a>
                      </li>
                      <li
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1.2vw' }}
                        >
                          En ligne
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex flex-row-reverse mb-2">
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Importer un nouveau plan
                    </button>
                  </div>
                </div>

                <Modal id={'exampleModal'} />

                <div className="d-flex">
                  <div style={{ width: '20%' }}>
                    <form
                      id="champ"
                      onSubmit={(e) => {
                        e.preventDefault()
                        e.target.reset()
                        setFilter('')
                        setData(trueData)
                      }}
                    >
                      <div className="contaienr-fluid d-flex">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="filtrer"
                          defaultValue={filter}
                          onChange={(e) => {
                            setFilter(e.target.value)
                          }}
                        />{' '}
                        <button className="btn btn-primary" type="submit">
                          <i class="ti ti-x"></i>
                        </button>
                      </div>
                    </form>{' '}
                    <div class="table-responsive">
                      <table
                        class="table"
                        id="myTable"
                        style={{ border: 'solid lightgray 1px' }}
                      >
                        <thead class="thead-light">
                          <tr style={{ backgroundColor: 'white' }}>
                            <td className="text-center">Liste plan</td>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length > 0 && (
                            <>
                              {data.map((e) => {
                                return (
                                  <>
                                    <tr
                                      onClick={(a) => {
                                        // setImage(e.nomMedia)
                                        // setMarkers(e.pins)
                                        // setId(e._id)
                                        // setShow(true)
                                        setNomMedia(e.nomMedia)
                                      }}
                                    >
                                      <td
                                        style={{
                                          backgroundColor: 'white',
                                          border: 'solid lightgray 1px',
                                        }}
                                      >
                                        <a
                                          href="#"
                                          className="nav-item nav-link active activeLocal"
                                        >
                                          {e.description}
                                        </a>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="droptarget"
                    onDrop={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      //console.log(document.querySelector('.image-marker__marker').style);
                    }}
                    onDragOver={(e) => {
                      e.preventDefault()
                    }}
                    style={{ width: '90%' }}
                  >
                    {show ? (
                      <>
                        <ImageMarker
                          src={`${process.env.REACT_APP_URL}/api/v1/ressources/plan/?planName=${image}&accessToken=${infoUser.token}`}
                          markers={markers}
                          onAddMarker={(marker) =>
                            setMarkers([...markers, marker])
                          }
                          markerComponent={(e) => (
                            <Pin
                              props={e}
                              idt={false}
                              idImage={id}
                              lot={allLot}
                              tacheSd={tacheSd}
                              tacheSc={tacheSc}
                              tacheSp={tacheSp}
                            />
                          )}
                        />{' '}
                        {/* <TransformWrapper>
                          <TransformComponent>
                            <ImageMarker
                              src={`${process.env.REACT_APP_URL}/api/v1/ressources/plan/?planName=${image}&accessToken=${infoUser.token}`}
                              markers={markers}
                              onAddMarker={(marker) =>
                                setMarkers([...markers, marker])
                              }
                              markerComponent={(e) => (
                                <Pin
                                  props={e}
                                  idt={false}
                                  idImage={id}
                                  lot={allLot}
                                  tacheSd={tacheSd}
                                  tacheSc={tacheSc}
                                  tacheSp={tacheSp}
                                />
                              )}
                            />{' '}
                          </TransformComponent>
                        </TransformWrapper> */}
                      </>
                    ) : (
                      <>
                        <h1 className="text-center text-danger">
                          {' '}
                          <u>Sélectionner un plan</u>{' '}
                        </h1>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {wait && (
        <>
          <div
            style={{
              position: 'absolute',
              top: '40%',
              left: '55%',
            }}
          >
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
      )}
    </>
  )
}

export default Observation
