const dateFormatter = dateToFormat => {
    let date = new Date(dateToFormat);
    let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('fr-FR', options);
};

const levenshteinDistance = (word1, word2) => {
    const m = word1.length;
    const n = word2.length;
  
    if (m === 0) return n;
    if (n === 0) return m;
  
    const dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));
  
    for (let i = 0; i <= m; i++) {
      dp[i][0] = i;
    }
  
    for (let j = 0; j <= n; j++) {
      dp[0][j] = j;
    }
  
    for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
        if (word1[i - 1] === word2[j - 1]) {
          dp[i][j] = dp[i - 1][j - 1];
        } else {
          dp[i][j] = 1 + Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1]);
        }
      }
    }
    return dp[m][n];
  };
  
/**
   const getDistByLevenshteinAlgo = (searchWord, data) => {
    let min = Infinity;
  
    const traverseObject = (obj) => {
      Object.values(obj).forEach((value) => {
        if (typeof value === "object") {
          traverseObject(value); // Parcours récursif pour les sous-objets
        } else {
          const dist = levenshteinDistance(searchWord, String(value).toLowerCase());
          if (dist < min) min = dist;
        }
      });
    };
  
    traverseObject(data);
  
    return min;
  };
 */

  /*const getDistByLevenshteinAlgo = (searchWord, data) => {
    let min = Infinity;
    Object.keys(data).forEach((key) => {
    const strObj = String(data[key]).toLowerCase()
      const dist = levenshteinDistance(searchWord, strObj);
      if (dist < min || strObj.includes(searchWord.toLowerCase())) min = dist; 
    });
  
    return min;
  };*/

  const fieldsToFilter = [
    "plan",
    "start_date",
    "end_date",
    "text",
    "titre",
    "prevuLe",
    "noteLe",
    "faitLe",
    "aLeverLe",
    "phase",
    "type",
    "dateBPE",
    "niveau",
    "zone",
    "prevision",
    "diffusion",
    "visa",
    "name",
    "lastname",
    "telephone",
    "email",
    "role",
    "acces",
    "entrepriseName",
    "societe",
    "entreprise",
    "entreprises",
    "localisation",
    "description",
    "pointsBloquants",
    "impactEntreprise",
    "commentaires",
    "tendance",
    "solutions",
    "demandePar",
    "ouvrageImpacte",
    "date",
    "evenement",
    "nombreJours",
    "observation",
    "MOADatePrevisionnel",
    "MOADateExecution",
    "MOAemisPar",
    "ICDatePrevisionnel",
    "ICDateExecution",
    "lot",
    "nom",
    "dateCR",
    "nomFichier",
    "idCR",
    "dateExport",
    "nomDocument",
    "idVisite",
    "dateDeCreation",
    "critere",
    "enCours",
    "precedent"
  ];
  
  function isDate(value) {
    return !isNaN(new Date(value).getTime());
  }
  
  function getDistByLevenshteinAlgo(searchWord, data) {
    const maxDistance = searchWord.length;
    let min = maxDistance;
  
    const traverseObject = (obj) => {
      if(obj)Object.entries(obj).forEach(([key, value]) => {
        if (fieldsToFilter.includes(key)) {
          if (typeof value === "object") {
            traverseObject(value);
          } else {
            if (isDate(value)) {
              value = dateFormatter(value);
            }
            const strObj = String(value).toLowerCase();
            if (strObj.includes(searchWord.toLowerCase())) {
              min = 0;
            } else {
              const dist = levenshteinDistance(searchWord, strObj);
              if (dist < min) min = dist;
            }
          }
        }
      });
    };
  
    traverseObject(data);
  
    return min;
  }
  
  function searchOptimizedData(searchWord, dataArray) {
    const dataCopy = [...dataArray];
  
    dataCopy.sort((objA, objB) => {
      const distanceA = getDistByLevenshteinAlgo(searchWord, objA);
      const distanceB = getDistByLevenshteinAlgo(searchWord, objB);
      return distanceA - distanceB;
    });
  
    return dataCopy;
  }
  export {searchOptimizedData};